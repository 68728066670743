import * as React from 'react';

const ShowSignInContext = React.createContext({
  
    setShowSignIn : '',
  

})

export default ShowSignInContext;
