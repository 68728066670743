import * as React from 'react';

const TargetOrgNameContext = React.createContext({
  
  
  setTargetOrgName: ""


})

export default TargetOrgNameContext;
