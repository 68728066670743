import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import CloudIcon from '@mui/icons-material/Cloud';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CompareIcon from '@mui/icons-material/Compare';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FolderIcon from '@mui/icons-material/Folder';
import InventoryIcon from '@mui/icons-material/Inventory';
import {Link} from 'react-router-dom';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const categories = [
  {
    id: 'Metadata Comparison',
    children: [
      {
        id: 'Source Orgs',
        icon: <CloudIcon />,
        active: true,
      },
      { id: 'Target Orgs', icon: <CloudDoneIcon />},
      { id: 'Comparison History', icon: <CompareIcon /> },
      { id: 'Deployment History', icon: <DriveFolderUploadIcon /> },
    ],
  },
  {
    id: 'Projects & Packages',
    children: [
      { id: 'Projects', icon: <FolderIcon /> }, 
      { id: 'Packages', icon: <FolderIcon /> },
      { id: 'Package Versions', icon: <InventoryIcon /> },
      { id: 'Packages Installation History', icon: <FolderIcon /> },
    ],
  },
];

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 0,

};






export default function Navigator(props) {
  const { ...other } = props;
  const navigate = useNavigate();

  const handleRefreshClick = () => {
    navigate('/Content0');
    window.location.reload();
  };

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
      
        <ListItem onClick={handleRefreshClick} component={Link} to="/Content0"  sx={{ ...item, ...itemCategory, fontSize: 16, color: '#fff', py: 2, px:9  }}>
        <ListItemIcon><HomeIcon /> </ListItemIcon>
          Home
        </ListItem>



        
          <Box sx={{ bgcolor: '#101F33' }}>
            <ListItem sx={{ py: 2, px: 2 }}>
            <ListItemButton component={Link} to="/MetaDataComparisonMain" sx={item}>
              <ListItemText sx={{ color: '#fff' }}>Metadata Comparison</ListItemText>
            </ListItemButton>
            </ListItem>
                
              <ListItem>
              <Stack>
                <ListItemButton component={Link} to="/SourceOrgsListNew" sx={item}>
                  <ListItemIcon><CloudIcon /></ListItemIcon>
                  <ListItemText>SF Orgs</ListItemText>
                </ListItemButton>
                <ListItemButton component={Link} to="/GitHubProjectList"  sx={item}>
                  <ListItemIcon><CloudIcon /></ListItemIcon>
                  <ListItemText>GitHub Repositories</ListItemText>
                </ListItemButton>
                <ListItemButton component={Link} to="/ComparisonHistoryMainContent" sx={item}>
                  <ListItemIcon><CompareIcon /></ListItemIcon>
                  <ListItemText>Comparison History</ListItemText>
                </ListItemButton>
                <ListItemButton component={Link} to="/DeploymentHistoryMainContent" sx={item}>
                  <ListItemIcon><DriveFolderUploadIcon /></ListItemIcon>
                  <ListItemText>Deployment History</ListItemText>
                </ListItemButton>
                </Stack>
              </ListItem>
          </Box>
          <Box sx={{ bgcolor: '#101F33' }}>
            <ListItem sx={{ py: 2, px: 2 }}>
            <ListItemButton component={Link} to="/Content1" sx={item}>
              <ListItemText sx={{ color: '#fff' }}>Projects & Packages</ListItemText>
              </ListItemButton>
            </ListItem>
                
              <ListItem>
              <Stack>
              {/*<ListItemButton component={Link} to="/DevHubOrgContent" sx={item}>
                  <ListItemIcon><CloudIcon /></ListItemIcon>
                  <ListItemText>Auth Dev Hub Orgs</ListItemText>
                </ListItemButton>
                <ListItemButton component={Link} to="/ScratchOrgContent" sx={item}>
                  <ListItemIcon><CloudIcon /></ListItemIcon>
                  <ListItemText>Auth Developer Orgs</ListItemText>
                </ListItemButton>*/}
                
                <ListItemButton component={Link} to="/ProjectsContent"  sx={item}>
                  <ListItemIcon><FolderIcon /></ListItemIcon>
                  <ListItemText>Projects</ListItemText>
                </ListItemButton>
                <ListItemButton component={Link} to="/PackageContent" sx={item}>
                  <ListItemIcon><FolderIcon /></ListItemIcon>
                  <ListItemText>Packages</ListItemText>
                </ListItemButton>
                <ListItemButton  component={Link} to="/InstallationHistoryMainContent" sx={item}>
                  <ListItemIcon><FolderIcon /> </ListItemIcon>
                  <ListItemText>Packages Installation History</ListItemText>
                </ListItemButton>
                
                </Stack>
              </ListItem>
          </Box>

          <Box sx={{ bgcolor: '#101F33' }}>
            <ListItem sx={{ py: 2, px: 2 }}>
            <ListItemButton component={Link} to="/SalesforceCodeAnalyzerMain" sx={item}>
              <ListItemText sx={{ color: '#fff' }}>Salesforce Code Analyzer</ListItemText>
              </ListItemButton>
            </ListItem>
                
              
          </Box>
          
      </List>
    </Drawer>
  );
}
