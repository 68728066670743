import React, { useState } from "react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import SourceOrgNameContext from "../../sourceOrgName-context";
import TargetOrgNameContext from "../../targetOrgName-context";
import SelectedCheckboxContext from "../../SelectedCheckbox-Context";
import { useContext } from "react";
import Backdrop from "@mui/material/Backdrop";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SfContext from "../../sf-context";
import SourceBranchContext from "../../sourceBranch-context";
import TargetBranchContext from "../../targetBranch-context";
import config from '../../Config';
import { useUser } from '../../UserContext';
import SelectedfilestosfanalyzerContext from '../../GlobalValues/selectedfilestosfanalyzer-context';
import AnalysisResultsContext from '../../GlobalValues/analysisResults-context';
import CommonBackdropContext from '../../GlobalValues/commonbackdrop-context';
import AnalyzerTargetOrgIdContext from '../../GlobalValues/analyzertargetorgid-context';
import { useNavigate } from 'react-router-dom';


export default function SalesforceCodeAnalyzerButton() {
  
  const {selectedFilesToSFAnalyzer} = useContext(SelectedfilestosfanalyzerContext);
  const userData = useUser();
  const {setCommonBackdrop} = useContext(CommonBackdropContext);
  const {setAnalysisResultsContext} = useContext(AnalysisResultsContext);
  const {analyzerTargetOrgId} = useContext(AnalyzerTargetOrgIdContext);


  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setCommonBackdrop(true);
      navigate('/SalesforceCodeAnalyzerResults');
      console.log(selectedFilesToSFAnalyzer);
      const analyzerParams = {filesToAnalyzeList: selectedFilesToSFAnalyzer, targetUsernameId: analyzerTargetOrgId, userId: userData.id, analyzedBy:userData.lastName};
      axios.defaults.timeout = config.requestTimeout; const response = await axios.post(config.basePath+'/analysis/analyzeSFCode', analyzerParams); 
      console.log("Request Sent");
      console.log(response.data); 
      setAnalysisResultsContext(response.data);
      
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setCommonBackdrop(false);
    }
  };



  return (
    <div>
      <Button
        onClick={handleSubmit}
        variant="contained"
        color="success"
        size="small"
        
        sx={{ mt: 1, mb: 0 }}
      >
        Analyze
      </Button>
     
    </div>
  );
}
