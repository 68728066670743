import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import {Backdrop, CircularProgress } from '@mui/material';

import {Link} from 'react-router-dom';
import Button  from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import SalesforceCodeAnalyzerFileSelectionTable from './SalesforceCodeAnalyzerFileSelectionTable';
import CommonBackdropContext from '../../GlobalValues/commonbackdrop-context';
import SalesforceCodeAnalyzerButton from './SalesforceCodeAnalyzerButton';


export default function SalesforceCodeAnalyzerFileSelectionPage() {


    const {commonBackdrop} = useContext(CommonBackdropContext);

  return (
   
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        
        <Grid item xs={9} sx={{ mt: -3 }}>
        <Typography component="h1" variant="h6">
            SELECT FILES TO ANALYZE
          </Typography>
        </Grid>
        <Grid container justifyContent="flex-end" item xs={3} sx={{ mt: -5, mr: 0, pr:1 }}>
        
        
                
              
           
        </Grid>
        <Grid item xs={8.3}>
          <SalesforceCodeAnalyzerFileSelectionTable />
          
                  
        </Grid>
       
       
        
        <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={commonBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item xs={6} sx={{ mt: 0 }}>
      <Button
                component={Link} to="/Deployment"
                variant="contained" 
                size="small" 
                sx={{ mt: 1 }}
                >
                  Back
                  </Button>
                  </Grid>
      <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 0 }}>
      <SalesforceCodeAnalyzerButton />
      </Grid>
      </Grid>
   
  );
}