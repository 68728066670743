
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import config from './Config';

const columns = [
  { field: 'id', 
    headerName: 'ID', 
    width: 95,
  },
  {
    field: 'developerOrg',
    headerName: 'Developer Org',
    width: 270,
    
  },
  {
    field: 'authDate',
    headerName: 'Auth Date',
    width: 150,
    
  },
  
];





export default function ScratchOrgList() {
    
    
    
    const [rowSelect, setRowSelect] = React.useState([]);
    const [orgs, setOrgs] = React.useState([]);
  

  useEffect(() => {
    // Fetch data using Axios with a GET request
    axios.defaults.timeout = config.requestTimeout;axios.post(config.basePath+'/OrgAuthorization/allDeveloperOrgs', {
      params: {
        isDeveloperOrg: true
      }
    })
      .then((response) => {
        const posts = response.data.map(obj => ({id: obj.id , developerOrg: obj.userName}));
        setOrgs(posts);
        
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);



  return (
    <Box sx={{ height: 350, width: '100%', fontSize:8 , mt:3 }}>
      <DataGrid
        rows={orgs}
        columns={columns}
        
        initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
              
            },
          }}

        pageSizeOptions={[5]}
        rowHeight={48}
        checkboxSelection

        onRowSelectionModelChange={(newRowSelect) => {
          setRowSelect(newRowSelect);
          console.log(rowSelect);
        }}
        rowSelection={rowSelect}
        disableRowSelectionOnClick
        
      />
      
            <Button
             
              type="submit"
              size="medium"     
              variant="contained" 
              disabled={rowSelect.length === 0}
              sx={{ mt: 1, }}
            >
              Delete
            </Button>
            
      
    </Box>
  );
}
