import * as React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import PackageTabs from './PackageTabs';
import MetadataComparisonTabs from './Tabs';
import ScratchOrg from './ScratchOrg';

export default function ScratchOrgContent() {
  return (
    
     
        
          <Box>
            <ScratchOrg />
          </Box>
      
      
    
  );
}
