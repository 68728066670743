import * as React from "react";
import { useEffect } from 'react';

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import axios from "axios";
import DevHubOrgList from "./DevHubOrgListNew";
import CloudIcon from "@mui/icons-material/Cloud";
import Divider from "@mui/material/Divider";
import SignIn from "./SignIn";
import SignInTarget from "./SignInTarget";
import CompareProjects from "./CompareProjects";
import { Routes, Route } from "react-router-dom";
import TargetOptions from "./TargetOptions";
import SourceOptions from "./SourceOptions";
import ShowSourceOptionsContext from "./showSourceOptions-context";
import { useContext } from "react";
import ShowTargetOptionsContext from "./showTargetOptions-context";
import SignInSourceControl from "./SignInSourceControl";
import ShowSignInContext from "./showSignIn-context";
import SignInTargetSourceControl from './SignInTargetSourceControl';
import ShowSignInTargetContext from "./showSignInTarget-context";
import SourceRepoIdContext from "./GlobalValues/sourcerepoid-context";
import SourceSFOrgIdContext from "./GlobalValues/sourcesforgid-context";
import TargetRepoIdContext from "./GlobalValues/targetrepoid-context";
import TargetSFOrgIdContext from "./GlobalValues/targetsforgid-context";


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function MetaDataComparisonMain() {
  const { showSourceOptions } = useContext(ShowSourceOptionsContext);
  const {showTargetOptions} = useContext(ShowTargetOptionsContext);
  const {showSignInTarget}= useContext(ShowSignInTargetContext);
  const {showSignIn}= useContext(ShowSignInContext);

 


  return (
    <Grid container justifyContent="center" spacing={0}>
      <Grid item xs={12}>
        <Typography
          sx={{ mb: 0, mt: 0, textTransform: "uppercase", textAlign: "center" }}
          component="h1"
          variant="h5"
        >
          Metadata Comparison
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginRight: 4,
            maxHeight:100

            
          }}
        >
         {
  (() => {
    if (showSourceOptions && !showSignIn) {
      return <SourceOptions />;
    } else if (!showSourceOptions && showSignIn) {
      return <SignIn />;
    } else if (!showSourceOptions && !showSignIn) {
      return <SignInSourceControl />;
    } 
  })()
}
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginRight: 4,
            maxHeight:100
          }}
        >
           {
  (() => {
    if (showTargetOptions && !showSignInTarget) {
      return <TargetOptions />;
    } else if (!showTargetOptions && showSignInTarget) {
      return <SignInTarget />;
    } else if (!showTargetOptions && !showSignInTarget) {
      return <SignInTargetSourceControl />;
    } 
  })()
}
           
          
        </Box>
      </Grid>
      <Grid item xs={10}></Grid>
      <Grid item xs={2}>
        <Box sx={{ mt: 35, position:"fixed" }}>
          <CompareProjects />
        </Box>
      </Grid>
    </Grid>
  );
}
