import React from "react";
import forceforgeioLogo from './forceforgeioLogo.png';



export default function ForceForgeIoLogo({ width, height }) {
    return (
        <div>
            <img src={forceforgeioLogo} alt="pic" style={{ width: `${width}px`, height: `${height}px` }} />
        </div>
    );
}