import * as React from 'react';

import Box from '@mui/material/Box';
import {Routes, Route} from 'react-router-dom';
import ForgotPassword from './ForgotPassword';
import AccountSignUpPage from './AccountSignUpPage';
import AccountSignInPage from './AccountSignInPage';
import ResetPassword from './ResetPassword';



export default function MainPaperBase() {
  return (

    <Box >
            <Routes>
            <Route index element={<AccountSignInPage />} />
                <Route path='/AccountSignUpPage' element={<AccountSignUpPage />} />
                <Route path='/AccountSignInPage' element={<AccountSignInPage />} />
                <Route path='/ForgotPassword' element={<ForgotPassword />} />
                <Route path='/ResetPassword' element={<ResetPassword />} />
                
               
                
          </Routes>
          
        </Box>
       
            
          
      
      
    
  );
}
