import * as React from 'react';

const CircularBackdropProjectPackageTabsContext = React.createContext({
  
  
  setCircularBackdropProjectPackageTabs: false


})

export default CircularBackdropProjectPackageTabsContext;
