import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from "react-router-dom";

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloudIcon from '@mui/icons-material/Cloud';
import axios from 'axios';
import CircularIndeterminate from './ProgressBar';
import PackageVersionImage from './CreatePackageVersionImage';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useUser } from './UserContext';
import PackageContext from './package-context';
import ProjectContext from './project-context';
import { useContext } from "react";
import PackIdContext from './id-context';
import config from './Config';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function CreatePackageVersion() {

  const [visible, setVisible] = React.useState(false);
    const [orgs, setOrgs] = React.useState([]);
    const [org, setOrg] = React.useState([]);
    const userData = useUser();
    const { pack } = useContext(PackageContext);
    const { project } = useContext(ProjectContext);
    const {packId} = useContext(PackIdContext);
    const [retrieveResponse, setRetrieveResponse] = React.useState("");
    const [retrieveProcessComplete, setRetrieveProcessComplete] = React.useState("");
    const [openPackageVersionResponseDialog, setOpenPackageVersionResponseDialog] = React.useState();
  
    useEffect(() => {
      // Fetch data using Axios with a GET request
      const user = {id: userData.id};
    axios.post(config.basePath+'/OrgAuthorization/allDevHubOrgs', user)
        .then((response) => {
          const posts = response.data.map(obj => ({id: obj.id , userName: obj.userName}));
          setOrgs(posts);
          
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }, []);

    const handleChange = (event) => {
      setOrg(event.target.value);
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const packVersion = {packageID: packId, devHubUsername: org, userName: userData.userName, userId: userData.id, packageName: pack, projectName: project};
        axios.defaults.timeout = config.requestTimeout; const response = await axios.post(config.basePath+'/PackageVersions/create', packVersion); 
        console.log(packVersion);
        console.log("Request Sent");
        console.log(response.data); 
        setRetrieveResponse(response.data);
      } catch (error) {
        console.error(error);
      }
    };

useEffect(() => {
    if (retrieveResponse) {
      setVisible(false);
      setRetrieveProcessComplete(`The Package Version has been sucessfully created. To install the Package, Use below generated ID: ${retrieveResponse}`);
      setOpenPackageVersionResponseDialog(true);
    }
  }, [retrieveResponse]);

  return (
    <ThemeProvider theme={defaultTheme}>
       
      <Container component="main" maxWidth="xs">
      <CssBaseline />
        
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <CloudIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Create Package Version
          </Typography>
          
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width:450 }}>
            
            <FormControl  fullWidth>
            <InputLabel id="label" >Select Dev Hub Org</InputLabel>
            <Select
            labelId="label" 
            required
            id="select"
            value={org}
            label="Select Username"
            onChange={handleChange}
            >
              {orgs.map((item) => (
              
                  <MenuItem key={item.id} value={item.userName}>{item.userName}</MenuItem>
             
  
              ))}
            </Select>
         
            <Button  onClick={() => setVisible(!visible)}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={retrieveProcessComplete !== ""}
            >
              Create Package Version
            </Button>
            </FormControl> 
          
          </Box>
          <Box>
          {visible && <Typography sx={{ p: 1, fontSize:15,  }} align="center">
                {'The process will take time. Plz bear with us for a while.'}
            </Typography>}
            {visible && <Typography sx={{ p: 1, fontSize:15,  }} align="center">
                {'Package Version Creating .... '}
            </Typography>}
          </Box>
          <Box marginLeft={70} marginRight={70}>
          {visible && <CircularIndeterminate />}
          </Box>
          <Typography align="center">
         
          </Typography>
          
        </Box>
        <Dialog
        open={openPackageVersionResponseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Successful!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {retrieveProcessComplete}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button component={Link} to="/Content0" autoFocus variant="contained">
            Finish
          </Button>
        </DialogActions>
      </Dialog>
      </Container>
    </ThemeProvider>
  );
}
