import * as React from 'react';

const AnalysisResultsContext = React.createContext({
  
  
  setAnalysisResultsContext: []


})

export default AnalysisResultsContext;
