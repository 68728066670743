import * as React from 'react';

import Box from '@mui/material/Box';
import ProjectList from './ProjectListNewOne';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';



export default function ProjectsContent() {
  return (
    
     
        
          
           
          <Box
          sx={{
            marginTop: -4,
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 0,
            marginBottom: -2,
          }}
          >
            <Typography sx={{
                  marginLeft: 0,
                  marginBottom: 1,
                  fontSize:20,
                  mt:1,
                  ml:1,
                  textAlign:'Justify',
                }}>
                  List of Salesforce DX Projects 
            </Typography>
          <ProjectList />
        </Box>
            
          
      
      
    
  );
}
