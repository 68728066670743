import * as React from 'react';

const SelectedfilestosfanalyzerContext = React.createContext({
  
  
  setSelectedFilesToSFAnalyzer: []


})

export default SelectedfilestosfanalyzerContext;
