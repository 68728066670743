import * as React from 'react';

const SourceOrgNameContext = React.createContext({
  
  
  setSourceOrgName: ""


})

export default SourceOrgNameContext;
