import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloudIcon from '@mui/icons-material/Cloud';
import axios from 'axios';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useUser } from './UserContext';
import ProjectContext from './project-context';
import { useContext } from "react";
import config from './Config';
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function RetrieveSource() {

  
  const [visible, setVisible] = React.useState(false);
  const [orgs, setOrgs] = React.useState([]);
  const [org, setOrg] = React.useState([]);
  const userData = useUser();
  const { project } = useContext(ProjectContext);
  const [retrieveResponse, setRetrieveResponse] = React.useState("");
  const [retrieveProcessComplete, setRetrieveProcessComplete] = React.useState("");
  const [openloading, setOpenloading] = React.useState(false);
  const [openRetrieveSourceResponseDialog, setOpenRetrieveSourceResponseDialog] = React.useState();


  useEffect(() => {
    // Fetch data using Axios with a GET request
    const user = {id: userData.id};
    axios.post(config.basePath+'/OrgAuthorization/allDeveloperOrgs', user)
      .then((response) => {
        const posts = response.data.map(obj => ({id: obj.id , userName: obj.userName}));
        setOrgs(posts);
        
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleChange = (event) => {
    setOrg(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpenloading(true);
    try {
      const source = { targetOrg: org, userName: userData.userName, userId: userData.id, projectName: project};
      axios.defaults.timeout = config.requestTimeout; const response = await axios.post(config.basePath+'/retrieve/doRetrieve', source); 
      console.log(source);
      console.log("Request Sent");
      console.log(response.data); 
      setRetrieveResponse(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (retrieveResponse) {
     
      setRetrieveProcessComplete(retrieveResponse);
      setOpenloading(false);
      setOpenRetrieveSourceResponseDialog(true);
      const timeoutId = setTimeout(() => {
        setOpenRetrieveSourceResponseDialog(false);
      
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [retrieveResponse]);


  return (
    <ThemeProvider theme={defaultTheme}>
       
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <CloudIcon />
          </Avatar>
          <Typography sx={{ mb: 2 }} component="h1" variant="h5">
          Retrieve Source
          </Typography>
          <Box sx={{
                mt: 0,
                width:'110%',
                mb: 2,
          }}
          >
        <Typography variant="body1" 
            sx={{
                color: 'darkblue',
                textAlign:'justify',
          }}
          >
          Select the Developer Org from where you want to retrieve the Metadata.
          </Typography>
        </Box>
          <Box component="form" onSubmit={handleSubmit} sx={{width:450}}>
          <FormControl  fullWidth>
          <InputLabel id="label" >Select Username</InputLabel>
          <Select
          labelId="label" 
          id="select"
          value={org}
          label="Select Username"
          onChange={handleChange}
          >
          {orgs.map((item) => (    
            <MenuItem key={item.id} value={item.userName}>{item.userName}</MenuItem>
         ))}
          </Select>
            <Button
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={retrieveProcessComplete !== ""}
            >
              Retrieve Source
            </Button>
        
            </FormControl>
            
                
        </Box>
        <Dialog
          open={openRetrieveSourceResponseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {retrieveProcessComplete}
            </DialogContentText>
          </DialogContent>
          
        </Dialog>
        </Box>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openloading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
      </Container>
    </ThemeProvider>
  );
}
