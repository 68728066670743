import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import ForceForgeIoLogo from "./Images/forceforgeioLogo";
import config from "./Config";
import {SignUpWithGoogle} from "./GoogleAuthantication/AuthWithGoogle";

const defaultTheme = createTheme();

export default function AccountSignUpPage() {
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [isAgreetermsConditions, setIsAgreetermsConditions] = React.useState(false);
  const [openloading, setOpenloading] = React.useState(false);
  const [openErrorDialog, setOpenErrorDialog] = React.useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = React.useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
  const [responseData, setResponseData] = React.useState("");

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    checkPasswords(e.target.value, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    checkPasswords(password, e.target.value);
  };

  const checkPasswords = (password, confirmPassword) => {
    if (password && confirmPassword && password === confirmPassword) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };

  const signUp = async (e) => {
    e.preventDefault();
    const user = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      password: password,
      email: email,
      isAgreeTermsConditions: isAgreetermsConditions,
    };
    console.info("user-->" + user);
    console.info("termsConditions-->" + isAgreetermsConditions);
    setOpenloading(true);
    try {
      axios.defaults.timeout = config.requestTimeout;
      const response = await axios.post(config.basePath + "/User/signup", user);
      console.log(response.data);
      setResponseData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (responseData.includes("Successfully created new account")) {
      setOpenloading(false);
      setOpenSuccessDialog(true);
    } else if (responseData.includes("Incorrect username or email")) {
      setOpenloading(false);
      setOpenErrorDialog(true);
    }
  }, [responseData]);

  const redirectLoginPage = () => {
    window.location.href = config.homePagePath;
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundColor: "#4481eb", // Hexadecimal color for sky blue
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginTop={20}
            // Adjust the height as needed
          >
            <ForceForgeIoLogo width={200} height={200} />
          </Box>
          <Box
            display="flex"
            marginTop={-2}
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              sx={{
                color: "white",
                width: "70%", // Set the width to 50%

                textAlign: "justify",
                // Center the text within the Typography component
              }}
              variant="subtitle1"
            >
              If your goal is to build an app and distribute it on AppExchange,
              Force Forge will do the both. Force Forge provides a container
              that you fill with metadata, and it holds the set of related
              features, customizations, and schema that make up your app.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 4,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign Up
            </Typography>
            <Box
              component="form"
              onSubmit={signUp}
              noValidate
              sx={{ mt: 1, width: 400 }}
            >
              <Grid container>
                <Grid item xs={6}>
                  <Box sx={{ width: 190 }}>
                    <TextField
                      margin="normal"
                      size="small"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      name="firstName"
                      autoComplete="firstName"
                      autoFocus
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ width: 190, ml: 1 }}>
                    <TextField
                      margin="normal"
                      size="small"
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="lastName"
                      autoFocus
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Box>
                </Grid>
              </Grid>

              <TextField
                margin="normal"
                size="small"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                size="small"
                required
                fullWidth
                type="tel"
                id="phoneNo"
                label="Phone Number"
                name="phoneNo"
                autoComplete="phoneNo"
                autoFocus
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <TextField
                margin="normal"
                size="small"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handlePasswordChange}
              />
              <TextField
                margin="normal"
                size="small"
                required
                fullWidth
                name="confirmpassword"
                label="Confirm Password"
                type="password"
                id="confirmpassword"
                autoComplete="confirmpassword"
                onChange={handleConfirmPasswordChange}
              />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAgreetermsConditions}
                      onChange={(event) =>
                        setIsAgreetermsConditions(event.target.checked)
                      }
                      size="small"
                    />
                  }
                  label={
                    <span style={{ fontSize: "11px" }}>
                      By signing up you agree to our Terms & Conditions and
                      Privacy Policy.
                    </span>
                  }
                />
              </FormGroup>
              <Button
              size='large'
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 1, mb: 1 }}
                disabled={isButtonDisabled}
              >
                Sign Up
              </Button>
              <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              paddingBottom:1,
              alignItems: "center",

            }}
          >
            <Typography variant="button" display="block">
              OR
            </Typography>
          </Box>
          <Box sx={{ display: 'flex',
                    justifyContent: 'center',
                    mb:1}}>
              <SignUpWithGoogle />
         
          </Box>
            </Box>
            <Box>
            
            </Box>
          </Box>
          <Box>
            <Button
              type="submit"
              onClick={redirectLoginPage}
              variant="contained"
              sx={{ mt: -2, mb: 0, ml: 4 }}
              size="small"
            >
              Back
            </Button>
          </Box>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openloading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Dialog
            open={openSuccessDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Success!"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Your account has been successfully created.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={redirectLoginPage} autoFocus variant="contained">
                Ok
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openErrorDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Alert!"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Incorrect Username/Email. Try again.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpenErrorDialog(false)}
                autoFocus
                variant="contained"
              >
                Finish
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}