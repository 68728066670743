import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import {Link} from 'react-router-dom';

import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import DataGridCompareDeploy from './Grid';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";


export default function RowAndColumnSpacing() {
  return (
   
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        
        <Grid item xs={9} sx={{ mt: -3 }}>
        <Typography component="h1" variant="h6">
            COMPARE & REVIEW
          </Typography>
        </Grid>
        <Grid container justifyContent="flex-end" item xs={3} sx={{ mt: -3, mr: 0, pr:1 }}>
        
                <Button
                component={Link} to="/Deployment"
                variant="contained" 
                size="large" 
                
                >
                  Draft Deployment</Button>
                
              
           
        </Grid>
        <Grid item xs={8.3}>
          <DataGridCompareDeploy />
        </Grid>
      </Grid>
   
  );
}