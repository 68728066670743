import * as React from 'react';

const OrgFilesForAnalysisContext = React.createContext({
  
  
  setOrgfilesforanalysis: []


})

export default OrgFilesForAnalysisContext;
