import * as React from 'react';

const TargetSFOrgIdContext = React.createContext({
  
  
  setTargetOrgId: ""


})

export default TargetSFOrgIdContext;
