import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import axios from 'axios';
import { useEffect } from 'react';
import {Link} from 'react-router-dom';
import config from './Config';


const defaultTheme = createTheme();

export default function ResetPassword() {

    const [username, setUsername]  = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");
    const [confirmNewPassword, setConfirmNewPassword] = React.useState("");
    const [openloading, setOpenloading] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [responseRequest, setResponseRequest] = React.useState("");


   

      useEffect(() => {
        if (responseRequest) {
          setOpenloading(false);
          setOpenDialog(true);
        
        }
      }, [responseRequest]);

      const handleRefreshClick = () => {
        window.location.reload();
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const userParam = { userName: username, password: newPassword, confirmPassword: confirmNewPassword};
          axios.defaults.timeout = config.requestTimeout; const response = await axios.post(config.basePath+'/User/resetPassword', userParam); 
          setOpenloading(true);
          console.log(userParam);
          console.log("Request Sent");
          setResponseRequest(response.data);
          console.log(response.data); 
          console.log(response);
        } catch (error) {
          console.error(error);
        }
      };





  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar variant="dense">
          
         
          <Typography variant="h6" color="inherit" component="div">
            Force Forge
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1, width:600 }}>
          <TextField
            type="username"
            size="small"
              margin="normal"
              required
              fullWidth
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
            type="password"
            size="small"
              margin="normal"
              required
              fullWidth
              label="New Password"
              name="newPassword"
              autoComplete="newPassword"
              autoFocus
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
            type="password"
            size="small"
              margin="normal"
              required
              fullWidth
              label="Confirm New Password"
              name="confirmNewPassword"
              autoComplete="confirmNewPassword"
              autoFocus
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              
            />
            <Button
             onClick={handleSubmit}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              
            >
            Reset Password 
            </Button>
            <Backdrop
                 sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                 open={openloading}
            >
                 <CircularProgress color="inherit" />
             </Backdrop>
      <Dialog
        open={openDialog}
        
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Reset Successful!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {responseRequest}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button component={Link} to="/AccountSignInPage" autoFocus variant="contained">
            Finish
          </Button>
        </DialogActions>
      </Dialog>
          
          </Box>
          
        </Box>
      </Container>
      <Box>
      <Button
             component={Link} to="/AccountSignInPage"
              type="submit"
              
              variant="contained"
              sx={{ mt: 25, mb: 0, ml:4 }}
              
            >
              Back 
            </Button>
      </Box>
    </ThemeProvider>
  );
}
