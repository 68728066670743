import { createStore, combineReducers, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk';
import sessionReducer from './sessionReducer';

// Combine reducers (if you have more, add them here)
const rootReducer = combineReducers({
  session: sessionReducer,
  // add other reducers here
});

// Create the Redux store with thunk middleware
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
