import * as React from 'react';

const SourceSFOrgIdContext = React.createContext({
  
  
  setSourceOrgId: ""


})

export default SourceSFOrgIdContext;
