import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CloudIcon from "@mui/icons-material/Cloud";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularIndeterminate from "./ProgressBar";
import SourceOrgNameContext from "./sourceOrgName-context";
import { useContext } from "react";
import ShowSourceOptionsContext from "./showSourceOptions-context";
import ShowSignInContext from "./showSignIn-context";
import { connect } from 'react-redux';
import SourceSFOrgIdContext from "./GlobalValues/sourcesforgid-context";
import SourceRepoIdContext from "./GlobalValues/sourcerepoid-context";
import config from './Config';

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const mapStateToProps = state => ({
  id: state.session.id
});

export default connect(mapStateToProps)(SignIn);


function SignIn(id) {
  const [visible, setVisible] = React.useState(false);
  const { setShowSourceOptions } = useContext(ShowSourceOptionsContext);
  const { setShowSignIn } = useContext(ShowSignInContext);
  const [openloading, setOpenloading] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [orgs, setOrgs] = React.useState([]);
  const [org, setOrg] = React.useState([]);
  const { setSourceOrgName } = useContext(SourceOrgNameContext);
  const [retrieveResponse, setRetrieveResponse] = React.useState();
  const [retrieveProcessComplete, setRetrieveProcessComplete] = React.useState("");
  const {setSourceOrgId} = useContext(SourceSFOrgIdContext);
  const {setSourceRepoId} = useContext(SourceRepoIdContext);
  const [selectedSourceSFId, setSelectedSourceSFId] = useState('');

  //const { sourceOrgId } = useContext(SourceSFOrgIdContext); it will be used at later stage

  const showSourceOptionCard = () => {
    setShowSourceOptions(true);
    setShowSignIn(false);
    setSourceOrgId(0);
    setSourceRepoId(0);
  };

  useEffect(() => {
    // Fetch data using Axios with a GET request
    console.log('id:',id.id)
    const userId = { id: id.id };
    axios.post(config.basePath+'/OrgAuthorization/allOrgs', userId)
      .then((response) => {
        const posts = response.data.map((obj) => ({
          id: obj.id,
          userName: obj.userName,
        }));
        setOrgs(posts);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  

  const handleChange = async (event) => {
    const slectOrg = event.target.value;
    const selectedItem = orgs.find(item => item.userName === slectOrg);
    setSelectedSourceSFId(selectedItem.id);
    setSourceOrgId(selectedItem.id);
    setSourceRepoId(0);
    setOrg(slectOrg);
    setSourceOrgName(slectOrg);
    
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <CloudIcon />
          </Avatar>
          <Typography sx={{ mb: 2 }} component="h1" variant="h5">
            Connect Source Org
          </Typography>

          <Box component="form" sx={{ mt: 1, width: 450 }}>
            <FormControl fullWidth>
              <InputLabel id="label" sx={{ mt: 0.6 }}>
                Select Username
              </InputLabel>
              <Select
                labelId="label"
                id="select"
                value={orgs.find(item => item.id === selectedSourceSFId)?.userName || ''}
                label="Select Username"
                onChange={handleChange}
              >
                {orgs.map((item) => (
                  <MenuItem key={item.id} value={item.userName}>
                    {item.userName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <Button
              onClick={showSourceOptionCard}
              size="small"
              variant="contained"
              sx={{ mt: 3 }}
            >
              Back
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
