import * as React from 'react';

const PackageContext = React.createContext({
  
  
  setPack: ""


})

export default PackageContext;
