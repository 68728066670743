import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import axios from 'axios';
import TargetOrgsList from './TargetOrgsListNew';
import CloudIcon from '@mui/icons-material/Cloud';
import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import config from './Config';



// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function TargetOrgs() {

  const [username, setUsername] = React.useState('');
  const [responseRequest, setResponseRequest] = React.useState('');
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openloading, setOpenloading] = React.useState(false);
  
  useEffect(() => {
    if (responseRequest) {
      setOpenloading(false);
      setOpenDialog(true);
    }
  }, [responseRequest]);

  const handleRefreshClick = () => {
    window.location.reload();
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setOpenloading(true);
      const addOrgDevice = { userName: username, isTargetOrg: true, isSourceOrg: false, isDevHubOrg: false, isDeveloperOrg: false};
      axios.defaults.timeout = config.requestTimeout; const response = await axios.post(config.basePath+'/OrgAuthorization/authOrgDevice', addOrgDevice); 
      
      
      // Convert the JSON string to a JavaScript object
      const parsedData = JSON.parse(JSON.stringify(response.data));
      setResponseRequest(parsedData);
      // Log the parsed JSON object
      console.log('Parsed JSON object:', parsedData);
      console.log(parsedData.user_code);
      
      console.log(addOrgDevice);
      console.log("Request Sent");
      console.log(response.data); // Handle success or failure
    } catch (error) {
      console.error(error);
    }
  };

  return (
    
      
    <Grid container spacing={0} >
    <Grid item xs={6}>
      <Box
        sx={{
          marginTop: 15,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginRight: 15,
          
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <CloudIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Authorize Target Org
        </Typography>
        
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width:450}}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Enter Username"
            name="email"
            autoComplete="email"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Button
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            AUTHORIZE
          </Button>
        </Box>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openloading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        <Dialog
        open={openDialog}
        
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Authorization!"}
        </DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center', marginBottom: '16px' }}>
              <span style={{ fontWeight: 'bold' }}>Use this device code:</span> {responseRequest.user_code} <br />
              <span style={{ fontWeight: 'bold' }}>Navigate to the following URL:</span> <a href={responseRequest.verification_uri} target="_blank" rel="noopener noreferrer">{responseRequest.verification_uri}</a> <br />
              <span style={{ fontWeight: 'bold' }}>to authorize the Salesforce organization.</span>
        </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRefreshClick} autoFocus variant="contained">
            Finish
          </Button>
        </DialogActions>
      </Dialog>
      </Box>
      </Grid>
      <Grid item xs={6}>
      <Box
        sx={{
          marginTop: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginRight: 4,
        }}
      >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <CloudDoneIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Authorized Target Orgs
        </Typography>
        
          <TargetOrgsList />
      </Box>



      </Grid>
      </Grid>
      
);
}
