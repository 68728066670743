import React, { useState } from "react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import SourceOrgNameContext from "./sourceOrgName-context";
import TargetOrgNameContext from "./targetOrgName-context";
import SelectedCheckboxContext from "./SelectedCheckbox-Context";
import { useContext } from "react";
import Backdrop from "@mui/material/Backdrop";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SfContext from "./sf-context";
import SourceBranchContext from "./sourceBranch-context";
import TargetBranchContext from "./targetBranch-context";
import config from './Config';
import { useUser } from './UserContext';

export default function DeployButton() {
  const { targetOrgName } = useContext(TargetOrgNameContext);
  const { sourceOrgName } = useContext(SourceOrgNameContext);
  const { selectedCheckbox } = useContext(SelectedCheckboxContext);
  const { isSalesforceOrg } = useContext(SfContext);
  const [openloading, setOpenloading] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openGitHubDeploymentDialog, setOpenGitHubDeploymentDialog] = React.useState(false);
  const [responseData, setResponseData] = React.useState();
  const [comments, setComments] = React.useState("");
  const { sourceBranch } = useContext(SourceBranchContext);
  const { targetBranch } = useContext(TargetBranchContext);
  const userData = useUser();

  const handleGitHubDeploy = async (e) => {
    e.preventDefault();
    try {
      setOpenGitHubDeploymentDialog(false);
      setOpenloading(true);
      const deployMetaData = {
        filesToDeploy: selectedCheckbox,
        sourceOrg: sourceOrgName,
        targetOrg: targetOrgName,
        comments: comments,
        branchName: targetBranch,
        userId: userData.id,
        deployedBy: userData.lastName,
      };
      const response = await axios.post(
        config.basePath+"/Deploy/deployGitChanges",
        deployMetaData
      );
      console.log(deployMetaData);
      console.log("Request Sent");
      setResponseData(response);
      // Handle success or failure
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGitHubReployDialog = () => {
    setOpenGitHubDeploymentDialog(true);
  };

  const handleDeployClick = async () => {
    if (isSalesforceOrg === true) {
      try {
        setOpenloading(true);
        const deployMetaData = {
          filesToDeploy: selectedCheckbox,
          sourceOrg: sourceOrgName,
          targetOrg: targetOrgName,
          userId: userData.id,
          deployedBy: userData.lastName,

        };
        const response = await axios.post(
          config.basePath+"/Deploy/deployApex",
          deployMetaData
        );
        console.log(deployMetaData);
        console.log("Request Sent");
        setResponseData(response.data); // Assuming you want to set responseData with response.data
        // Handle success or failure
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    } else {
      handleGitHubReployDialog();
    }
  };

  useEffect(() => {
    if (responseData) {
      setOpenloading(false);
      setOpenDialog(true);
    }
  }, [responseData]);

  return (
    <div>
      <Button
        onClick={handleDeployClick}
        variant="contained"
        color="success"
        size="large"
        sx={{ mt: 4, mb: 0 }}
      >
        DEPLOY
      </Button>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openloading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Deployment Successful!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Files are successfully depolyed to {targetOrgName}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button component={Link} to="/Content0" autoFocus variant="contained">
            Finish
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openGitHubDeploymentDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"GitHub Deployment Alert!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Enter the changes to proceed further.
          </DialogContentText>
          <Box component="form" noValidate sx={{ mt: 1, width: 400 }}>
            <TextField
              margin="normal"
              required
              label="Comments"
              id="comments"
              name="comments"
              autoComplete="comments"
              multiline
              rows={2}
              fullWidth
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenGitHubDeploymentDialog(false)}
            autoFocus
            variant="contained"
          >
            Back
          </Button>
          <Button
            onClick={handleGitHubDeploy}
            color="success"
            autoFocus
            variant="contained"
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
