import * as React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import PackageTabs from './PackageTabs';
import MetadataComparisonTabs from './Tabs';
import SourceOrgs from './SourceOrgs';

export default function SourceOrgsContent() {
  return (
    
     
        
          <Box>
            <SourceOrgs />
          </Box>
      
      
    
  );
}
