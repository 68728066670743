import React, { PureComponent } from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';

  const oldCode = `
  {
    "name": "Original name",
    "description": null
  }
  `;
  const newCode = `
  {
    "name": "My updated name",
    "description": "Brand new description",
    "status": "running"
  }
  `;

class Diff extends PureComponent {
  render = () => {
    return (
      <ReactDiffViewer oldValue={oldCode} newValue={newCode} compareMethod={DiffMethod.WORDS_WITH_SPACE} splitView={true} />
    );
  };
}

export default Diff;