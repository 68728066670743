import * as React from 'react';

const AuthContext = React.createContext({
  authenticated: false,
 
  setUser: null,

})

export default AuthContext;
