import * as React from 'react';
import { useEffect } from 'react';
import { useContext } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloudIcon from '@mui/icons-material/Cloud';
import axios from 'axios';
import { useUser } from './UserContext';
import ProjectContext from "./project-context";
import PackageContext from './package-context';
import CircularIndeterminate from './ProgressBar';
import SelectedCheckboxContext from './SelectedCheckbox-Context';
import SelectedPackVersionContext from './SelectedPackVersion-Context';
import Backdrop from '@mui/material/Backdrop';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Link} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import config from './Config';




// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function HttpsGitRepo() {
    const userData = useUser();

    const [visible, setVisible] = React.useState(false);
    const [targetUsername, setTargetUsername] = React.useState('');
    const [responseInstallPackage, setResponseInstallPackage] = React.useState("");
    const {selectedPackVersionValue} = useContext(SelectedPackVersionContext);
    const [openloading, setOpenloading] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [username, setUsername] = React.useState('');
    const [accessToken, setAccessToken] = React.useState('');
    const [repoURL, setRepoURL] = React.useState('');

 
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        setOpenloading(true);
        const httpsGitRepoParams = { gitHubUsername: username, accessToken:accessToken, repoURL: repoURL, userName: userData.userName, userId: userData.id};
        axios.defaults.timeout = config.requestTimeout; const response = await axios.post(config.basePath+'/Git/connectHttpGitRepo', httpsGitRepoParams); 
        
        console.log("Request Sent");
        console.log(response.data); 
       
        setResponseInstallPackage(response.data);
      } catch (error) {
        console.error(error);
      }
    };


    useEffect(() => {
      if (responseInstallPackage) {
        setOpenloading(false);
        setOpenDialog(true); 
      }
    }, [responseInstallPackage]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: -2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <CloudIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Connect GitHub
          </Typography>
          <Typography  variant="body1" 
            sx={{
                color: 'darkblue',
                textAlign:'justify',
                mt:2,
                width:'110%',
          }}
          >
          Enter the following GitHub credentials to clone the repository in the local directory.
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width:450 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              
            />
            <TextField
              margin="normal"
              type='password'
              required
              fullWidth
              label="Access Token"
              name="accessToken"
              autoComplete="accessToken"
              autoFocus
              value={accessToken}
              onChange={(e) => setAccessToken(e.target.value)}
              
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="RepoURL"
              name="repoURL"
              autoComplete="repoURL"
              autoFocus
              value={repoURL}
              onChange={(e) => setRepoURL(e.target.value)}
              
            />
            
            <Button
              onClick={() => setVisible(!visible)}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, textTransform:'uppercase' }}
              
            >
            Authenticate
            </Button>
          </Box>
          <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openloading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={openDialog}
        
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Connection Alert!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {responseInstallPackage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button component={Link} to="/Content0" autoFocus variant="contained">
            Finish
          </Button>
        </DialogActions>
      </Dialog>
        </Box>
        
      </Container>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={1} sx={{ml:0, mt:6, pb:0, mb:0 }}>
        <Button
            component={Link} to="/GitHubProjectList"
            variant="contained" 
            size="medium" 
            fullWidth
            
            >
             Back
            </Button>
        </Grid>
        </Grid>
    </ThemeProvider>
  );
}
