import * as React from 'react';

const ComparisonOrgsDataContext = React.createContext({
  
  
  setComparisonOrgsData: []


})

export default ComparisonOrgsDataContext;
