import React from "react";
import logo2 from './Images/logo2.png';



export default function Logo2(){
    return(
        <div>
            <img src={logo2} alt="pic" />
        </div>
    )
}