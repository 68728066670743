export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const CHANGE_STATE = 'CHANGE_STATE';
export const CHANGE_USERID = 'CHANGE_USERID';
export const SET_COMPARISON_DATA = 'SET_COMPARISON_DATA';

export const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

export const loginSuccess = (id) => ({
  type: LOGIN_SUCCESS,
  payload: id,
});

export const loginFailure = (id) => ({
  type: LOGIN_FAILURE,
  payload: id,
});

export const logout = () => ({
  type: LOGOUT,
});

export const setAuthState = (isAuthenticated) => ({
  type: CHANGE_STATE,
  payload: isAuthenticated,
});

export const setUserId = (id) => ({
  type: CHANGE_USERID,
  payload: id,
});

export const setComparisonData = (data) => ({
  type: SET_COMPARISON_DATA,
  payload: data,
});