import { AppRouter } from "./AppRouter";
import * as React from "react";
import ProjectContext from "./project-context";
import PackageContext from "./package-context";
import SourceOrgNameContext from "./sourceOrgName-context";
import TargetOrgNameContext from "./targetOrgName-context";
import ShowSourceOptionsContext from "./showSourceOptions-context";
import PackIdContext from "./id-context";
import { UserProvider } from './UserContext';
import SelectedCheckboxContext from "./SelectedCheckbox-Context";
import SelectedPackVersionContext from "./SelectedPackVersion-Context";
import OrgTypeContext from "./OrgType-Context";
import { useEffect } from "react";
import ShowTargetOptionsContext from "./showTargetOptions-context";
import ShowSignInContext from "./showSignIn-context";
import ShowSignInTargetContext from "./showSignInTarget-context";
import SfContext from "./sf-context";
import SourceBranchContext from "./sourceBranch-context";
import TargetBranchContext from "./targetBranch-context";
import SourceSFOrgIdContext from "./GlobalValues/sourcesforgid-context";
import TargetSFOrgIdContext from "./GlobalValues/targetsforgid-context";
import SourceRepoIdContext from "./GlobalValues/sourcerepoid-context";
import TargetRepoIdContext from "./GlobalValues/targetrepoid-context";
import AnalysisResultsContext from "./GlobalValues/analysisResults-context";
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setUserId } from './sessionActions';
import ComparisonOrgsDataContext from "./GlobalValues/comparisonorgsdata-context";
import ComparisonOrgsDataBackdropContext from "./GlobalValues/comparisonorgsdatabackdrop-context";
import {  GoogleOAuthProvider } from "@react-oauth/google";
import CircularBackdropProjectPackageTabsContext from "./GlobalValues/circularBackdropProjectPackageTabs-context";
import CommonBackdropContext from "./GlobalValues/commonbackdrop-context";
import OrgFilesForAnalysisContext from "./GlobalValues/orgfilesforanalysis-context";
import SelectedfilestosfanalyzerContext from "./GlobalValues/selectedfilestosfanalyzer-context";
import AnalyzerTargetOrgIdContext from "./GlobalValues/analyzertargetorgid-context";


const mapStateToProps = state => ({
  id: state.session.id
});

export default connect(mapStateToProps)(App);


function App({ id }) {
  const [project, setProject] = React.useState();
  const [pack, setPack] = React.useState();
  const [isSalesforceOrg, setIsSalesforceOrg] = React.useState(false);
  const [sourceOrgName, setSourceOrgName] = React.useState();
  const [targetOrgName, setTargetOrgName] = React.useState();
  const [selectedCheckbox, setSelectedCheckbox] = React.useState([]);
  const [selectedPackVersionValue, setSelectedPackVersionValue] = React.useState([]);
  const [showSourceOptions, setShowSourceOptions] = React.useState(true);
  const [showTargetOptions, setShowTargetOptions] = React.useState(true);
  const [showSignIn, setShowSignIn] = React.useState(false);
  const [showSignInTarget, setShowSignInTarget] = React.useState(false);
  const [sourceBranch, setSourceBranch] = React.useState();
  const [orgType, setOrgType] = React.useState();
  const [targetBranch, setTargetBranch] = React.useState();
  const [packId, setPackId] = React.useState();
  const [sourceOrgId, setSourceOrgId] = React.useState();
  const [targetOrgId, setTargetOrgId] = React.useState();
  const [sourceRepoId, setSourceRepoId] = React.useState();
  const [targetRepoId, setTargetRepoId] = React.useState();
  const [comparisonOrgsData, setComparisonOrgsData] = React.useState();
  const [comparisonOrgsDataBackdrop, setComparisonOrgsDataBackdrop] = React.useState(false);
  const [circularBackdropProjectPackageTabs, setCircularBackdropProjectPackageTabs] = React.useState(false);
  const [analysisResultsContext, setAnalysisResultsContext] = React.useState([]);
  const [orgfilesforanalysis, setOrgfilesforanalysis] = React.useState([]);
  const [selectedFilesToSFAnalyzer, setSelectedFilesToSFAnalyzer] = React.useState();
  const [commonBackdrop, setCommonBackdrop] = React.useState(false);
  const [analyzerTargetOrgId, setAnalyzerTargetOrgId] = React.useState();


  const dispatch = useDispatch();



  const clientId = "704054078616-mf82qcq2ghgg0s5tbmhqvlrba1po6m18.apps.googleusercontent.com";
  useEffect(() => {
    const isUserIdStored = localStorage.getItem('id');
    
      dispatch(setUserId(JSON.parse(isUserIdStored)));
   
  }, [dispatch]);


  useEffect(() => {
    localStorage.setItem('id', id);
    console.log('idparsed',id);
  }, [id]);

  return (
    
    <GoogleOAuthProvider clientId={clientId}>
        <UserProvider id={id}>
          <PackIdContext.Provider value={{packId, setPackId}}>
          <OrgTypeContext.Provider value={{ orgType, setOrgType }}>
            <SourceOrgNameContext.Provider
              value={{ sourceOrgName, setSourceOrgName }}
            >
              <TargetOrgNameContext.Provider
                value={{ targetOrgName, setTargetOrgName }}
              >
                <ProjectContext.Provider value={{ project, setProject }}>
                  <PackageContext.Provider value={{ pack, setPack }}>
                   
                      <SelectedCheckboxContext.Provider
                        value={{ selectedCheckbox, setSelectedCheckbox }}
                      >
                        <SelectedPackVersionContext.Provider
                          value={{
                            selectedPackVersionValue,
                            setSelectedPackVersionValue,
                          }}
                        >
                          <ShowSourceOptionsContext.Provider
                            value={{ showSourceOptions, setShowSourceOptions }}
                          >
                            <ShowTargetOptionsContext.Provider
                              value={{
                                showTargetOptions,
                                setShowTargetOptions,
                              }}
                            >
                              <ShowSignInContext.Provider
                                value={{ showSignIn, setShowSignIn }}
                              >
                                <ShowSignInTargetContext.Provider
                                  value={{
                                    showSignInTarget,
                                    setShowSignInTarget,
                                  }}
                                >
                                  <SfContext.Provider
                                    value={{
                                      isSalesforceOrg,
                                      setIsSalesforceOrg,
                                    }}
                                  >
                                    <SourceBranchContext.Provider
                                      value={{ sourceBranch, setSourceBranch }}
                                    >
                                      <TargetBranchContext.Provider
                                        value={{
                                          targetBranch,
                                          setTargetBranch,
                                        }}>
                                        <SourceSFOrgIdContext.Provider
                                        value={{
                                          sourceOrgId, 
                                          setSourceOrgId,
                                        }}
                                      >
                                        <TargetSFOrgIdContext.Provider
                                        value={{
                                          targetOrgId, 
                                          setTargetOrgId,
                                        }}
                                        >
                                          <SourceRepoIdContext.Provider
                                          value={{
                                            sourceRepoId, 
                                            setSourceRepoId,
                                          }}
                                          >
                                            <TargetRepoIdContext.Provider
                                            value={{
                                              targetRepoId, 
                                              setTargetRepoId,
                                            }}
                                            >
                                              <ComparisonOrgsDataContext.Provider
                                              value={{
                                                comparisonOrgsData, 
                                                setComparisonOrgsData,
                                              }}>
                                                <ComparisonOrgsDataBackdropContext.Provider
                                                value={{
                                                  comparisonOrgsDataBackdrop, 
                                                  setComparisonOrgsDataBackdrop,
                                                }}>
                                                  <CircularBackdropProjectPackageTabsContext.Provider
                                                  value={{
                                                    circularBackdropProjectPackageTabs, 
                                                    setCircularBackdropProjectPackageTabs,
                                                  }}>
                                                    <AnalysisResultsContext.Provider
                                                    value={{
                                                      analysisResultsContext, 
                                                      setAnalysisResultsContext,
                                                    }}>
                                                      <CommonBackdropContext.Provider
                                                      value={{
                                                        commonBackdrop, 
                                                        setCommonBackdrop,
                                                      }}>
                                                        <OrgFilesForAnalysisContext.Provider
                                                        value={{
                                                          orgfilesforanalysis, 
                                                          setOrgfilesforanalysis,
                                                        }}>
                                                          <SelectedfilestosfanalyzerContext.Provider
                                                          value={{
                                                            selectedFilesToSFAnalyzer,
                                                            setSelectedFilesToSFAnalyzer,
                                                          }}>
                                                            <AnalyzerTargetOrgIdContext.Provider
                                                            value={{
                                                              analyzerTargetOrgId,
                                                              setAnalyzerTargetOrgId,
                                                            }}>
                                        <AppRouter />
                                        </AnalyzerTargetOrgIdContext.Provider>
                                        </SelectedfilestosfanalyzerContext.Provider>
                                        </OrgFilesForAnalysisContext.Provider>
                                        </CommonBackdropContext.Provider>
                                        </AnalysisResultsContext.Provider>
                                        </CircularBackdropProjectPackageTabsContext.Provider>
                                        </ComparisonOrgsDataBackdropContext.Provider>
                                        </ComparisonOrgsDataContext.Provider>
                                        </TargetRepoIdContext.Provider>
                                        </SourceRepoIdContext.Provider>
                                        </TargetSFOrgIdContext.Provider>
                                        </SourceSFOrgIdContext.Provider>
                                      </TargetBranchContext.Provider>
                                    </SourceBranchContext.Provider>
                                  </SfContext.Provider>
                                </ShowSignInTargetContext.Provider>
                              </ShowSignInContext.Provider>
                            </ShowTargetOptionsContext.Provider>
                          </ShowSourceOptionsContext.Provider>
                        </SelectedPackVersionContext.Provider>
                      </SelectedCheckboxContext.Provider>
                  
                  </PackageContext.Provider>
                </ProjectContext.Provider>
              </TargetOrgNameContext.Provider>
            </SourceOrgNameContext.Provider>
          </OrgTypeContext.Provider>
          </PackIdContext.Provider>
        </UserProvider>
        </GoogleOAuthProvider>
 );
}

