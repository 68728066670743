import * as React from 'react';
import {useState, useEffect } from 'react';
import { useContext } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloudIcon from '@mui/icons-material/Cloud';
import axios from 'axios';
import { useUser } from '../../UserContext';
import Backdrop from '@mui/material/Backdrop';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Link} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import config from '../../Config';
import { connect } from 'react-redux';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import AnalysisResultsContext from '../../GlobalValues/analysisResults-context';
import CommonBackdropContext from '../../GlobalValues/commonbackdrop-context';
import OrgFilesForAnalysisContext from "../../GlobalValues/orgfilesforanalysis-context";
import AnalyzerTargetOrgIdContext from "../../GlobalValues/analyzertargetorgid-context";

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const mapStateToProps = state => ({
  id: state.session.id
});

export default connect (mapStateToProps)(SalesforceCodeAnalyzerMain);

function SalesforceCodeAnalyzerMain(id) {

    const [responseAnalyzer, setResponseAnalyzer] = React.useState("");
    const [orgs, setOrgs] = React.useState([]);
    const [selectedSourceSFId, setSelectedSourceSFId] = useState('');
    const userData = useUser();
    const [violations, setViolations] = useState([]);
    const {setAnalysisResultsContext} = useContext(AnalysisResultsContext);
    const {analysisResultsContext} = useContext(AnalysisResultsContext);
    const {setCommonBackdrop} = useContext(CommonBackdropContext);
    const {setOrgfilesforanalysis} =  useContext(OrgFilesForAnalysisContext);
    const {setAnalyzerTargetOrgId} = useContext(AnalyzerTargetOrgIdContext);
    const {analyzerTargetOrgId} = useContext(AnalyzerTargetOrgIdContext);
    const navigate = useNavigate();

    useEffect(() => {
      // Fetch data using Axios with a GET request
      console.log('id:',id.id)
      const userId = { id: id.id };
      axios.post(config.basePath+'/OrgAuthorization/allOrgs', userId)
        .then((response) => {
          const posts = response.data.map((obj) => ({
            id: obj.id,
            userName: obj.userName,
          }));
          setOrgs(posts);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }, []);


    const handleChange = async (event) => {
      const slectOrg = event.target.value;
      const selectedItem = orgs.find(item => item.userName === slectOrg);
      setSelectedSourceSFId(selectedItem.id);
      setAnalyzerTargetOrgId(selectedItem.id);
    };
 

    const handleProjectSubmit = async (e) => {
      e.preventDefault();
      try {
        setCommonBackdrop(true);
        navigate('/SalesforceCodeAnalyzerFileSelectionPage');

        const analyzerParams = { orgId: analyzerTargetOrgId, userId: userData.id};
        axios.defaults.timeout = config.requestTimeout; const response = await axios.post(config.basePath+'/analysis/retrieveFilesForAnalysis', analyzerParams); 
       console.log (response.data);
        const files = response.data.orgFilesSFAnalyzer;
        console.info("files-->" + files);
        const posts = files.map(obj => {
          const fullFileName = obj.filePath.split('/').pop();
          const refineFileName = fullFileName.split('.')[0];
          return {
            id: obj.id,
            fileName: refineFileName,
            filePath: obj.filePath,
            metadataType: obj.metaDataType,
            referenceObject: obj.referenceObject,
          };
        });
  
        setOrgfilesforanalysis(posts);
        
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setCommonBackdrop(false);
      }
    };


    useEffect(() => {
      if (responseAnalyzer) {
      }
    }, [responseAnalyzer]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
      
        <Box
          sx={{
            marginTop: 7,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 2, bgcolor: 'secondary.main' }}>
            <CloudIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
           Salesforce Code Analyzer
          </Typography>
          <Typography textAlign={'left'} variant="body1" sx={{ mt: 1 }}
           
          >
          Select the SF Org you want to analyze the code for
          </Typography>
          
          
          <Box component="form" sx={{ mt: 2, width: 450 }}>
            <FormControl fullWidth>
              <InputLabel id="label" sx={{ mt: 0.6 }}>
                Select Username
              </InputLabel>
              <Select
                labelId="label"
                id="select"
                value={orgs.find(item => item.id === selectedSourceSFId)?.userName || ''}
                label="Select Username"
                onChange={handleChange}
              >
                {orgs.map((item) => (
                  <MenuItem key={item.id} value={item.userName}>
                    {item.userName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{
                width: 430,
                color: 'darkblue',
                textAlign:'right',
                marginLeft: 'auto',
                mt:1,
              
                    }}>
            <Button   component={Link} to="/SelectOrgType" autoFocus variant="text">
                      <AddIcon /> Add Org 
            </Button>
          </Box>
            <Button
             onClick={handleProjectSubmit}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 2 }}
              
            >
            Analyze
            </Button>
            </Box>





          
          
        </Box>
      </Container>
    </ThemeProvider>
  );
}
