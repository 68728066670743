import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CloudIcon from "@mui/icons-material/Cloud";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularSmall from "./ProgressBar";
import TargetOrgNameContext from "./targetOrgName-context";
import { useContext } from "react";
import ShowTargetOptionsContext from "./showTargetOptions-context";
import ShowSignInTargetContext from "./showSignInTarget-context";
import Backdrop from "@mui/material/Backdrop";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import SfContext from './sf-context';
import { connect } from 'react-redux';
import TargetSFOrgIdContext from "./GlobalValues/targetsforgid-context";
import TargetRepoIdContext from "./GlobalValues/targetrepoid-context";
import config from './Config';

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();
const mapStateToProps = state => ({
  id: state.session.id
});

export default connect(mapStateToProps)(SignInTarget);

function SignInTarget(id) {
  const [visible, setVisible] = React.useState(false);
  const { setShowTargetOptions } = useContext(ShowTargetOptionsContext);
  const { setShowSignInTarget } = useContext(ShowSignInTargetContext);
  const {setIsSalesforceOrg} = useContext(SfContext);
  const [orgs, setOrgs] = React.useState([]);
  const [org, setOrg] = React.useState([]);
  const { setTargetOrgName } = useContext(TargetOrgNameContext);
  const [retrieveResponse, setRetrieveResponse] = React.useState();
  const [retrieveProcessComplete, setRetrieveProcessComplete] = React.useState("");
  const [openloading, setOpenloading] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedTargetSFId, setSelectedTargetSFId] = useState('');
  const {setTargetOrgId} = useContext(TargetSFOrgIdContext);
  const {setTargetRepoId} = useContext(TargetRepoIdContext);


  //const { targetOrgId } = useContext(TargetSFOrgIdContext); it will be used at later stage


  const showTargetOptionCard = () => {
    setShowTargetOptions(true);
    setShowSignInTarget(false);
    setTargetOrgId(0);
    setTargetRepoId(0);
  };

  useEffect(() => {
    // Fetch data using Axios with a GET request
    console.log('id:',id.id)
    const userId = { id: id.id };

    axios.post(config.basePath+'/OrgAuthorization/allOrgs', userId)
      .then((response) => {
        const posts = response.data.map((obj) => ({
          id: obj.id,
          userName: obj.userName,
        }));
        setOrgs(posts);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  

  const handleChange = async (event) => {
    const slectOrg = event.target.value;
    const selectedItem = orgs.find(item => item.userName === slectOrg);
    setSelectedTargetSFId(selectedItem.id);
    setTargetOrgId(selectedItem.id);
    setTargetRepoId(0);
    setOrg(slectOrg);
    setIsSalesforceOrg(true);  
    setTargetOrgName(slectOrg);
    
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <CloudIcon />
          </Avatar>
          <Typography sx={{ mb: 2 }} component="h1" variant="h5">
            Connect Target Org
          </Typography>

          <Box component="form" sx={{ mt: 1, width: 450 }}>
            <FormControl fullWidth>
              <InputLabel id="label" sx={{ mt: 0.6 }}>
                Select Username
              </InputLabel>
              <Select
                labelId="label"
                id="select"
                value={orgs.find(item => item.id === selectedTargetSFId)?.userName || ''}
                label="Select Username"
                onChange={handleChange}
              >
                {orgs.map((item) => (
                  <MenuItem key={item.id} value={item.userName}>
                    {item.userName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

           
            <Button
              onClick={showTargetOptionCard}
              size="small"
              variant="contained"
              sx={{ mt: 3 }}
            >
              Back
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
