import React, { useEffect, useState} from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CloudIcon from "@mui/icons-material/Cloud";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularSmall from "./ProgressBar";
import TargetOrgNameContext from "./targetOrgName-context";
import { useContext } from "react";
import ShowTargetOptionsContext from "./showTargetOptions-context";
import PolylineTwoToneIcon from "@mui/icons-material/PolylineTwoTone";
import ShowSignInTargetContext from "./showSignInTarget-context";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import TargetBranchContext from "./targetBranch-context";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { connect } from 'react-redux';
import TargetRepoIdContext from "./GlobalValues/targetrepoid-context";
import TargetSFOrgIdContext from "./GlobalValues/targetsforgid-context";
import config from './Config';

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();
const mapStateToProps = state => ({
  id: state.session.id
});

export default connect(mapStateToProps)(SignInTargetSourceControl);


function SignInTargetSourceControl(id) {
  const [visible, setVisible] = React.useState(false);
  const { setShowTargetOptions } = useContext(ShowTargetOptionsContext);
  const { setShowSignInTarget } = useContext(ShowSignInTargetContext);
  const [orgs, setOrgs] = React.useState([]);
  const [org, setOrg] = React.useState([]);
  const { setTargetOrgName } = useContext(TargetOrgNameContext);
  const [retrieveResponse, setRetrieveResponse] = React.useState();
  const [retrieveProcessComplete, setRetrieveProcessComplete] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [branches, setBranches] = React.useState([]);
  const { setTargetBranch } = useContext(TargetBranchContext);
  const [openloading, setOpenloading] = React.useState(false);
  const [selectedTargetRepoId, setSelectedTargetRepoId] = useState(null);
  const {setTargetRepoId} = useContext(TargetRepoIdContext);
  const {setTargetOrgId} = useContext(TargetSFOrgIdContext);


  //const { targetRepoId } = useContext(TargetRepoIdContext); it will be used at later stage


  const showTargetOptionCard = () => {
    setShowTargetOptions(true);
    setShowSignInTarget(false);
    setTargetOrgId(0);
    setTargetRepoId(0);
  };

  useEffect(() => {
    // Fetch data using Axios with a GET request
    console.log('id:',id.id)
    const userId = { id: id.id };

    axios.post(config.basePath+'/Git/allRepositories', userId)
      .then((response) => {
        const posts = response.data.map((obj) => ({
          id: obj.id,
          repoName: obj.repoName,
          repourl: obj.repoURL,
          gitHubUsername: obj.gitHubUsername,
        }));
        setOrgs(posts);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleChange = async (event) => {
    const slectOrg = event.target.value;
    const selectedItem = orgs.find(item => item.repoName === slectOrg);
    setSelectedTargetRepoId(selectedItem.id);
    setTargetRepoId(selectedItem.id);
    setTargetOrgId(0);
    setOrg(slectOrg);
    setTargetOrgName(slectOrg);
    setOpenloading(true);
    setVisible(true);
    try {
      const response = await axios.get(
        config.basePath+"/Git/allBranches",
        {
          params: {
            repoName: slectOrg,
          },
        }
      );
      setBranches(response.data);
      console.log(response.data);
      setOpenloading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleBranchChange = async (event) => {
    const selectBranch = event.target.value;
    setTargetBranch(selectBranch);
    console.log(selectBranch);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <PolylineTwoToneIcon />
          </Avatar>
          <Typography sx={{ mb: 2 }} component="h1" variant="h5">
            Connect Target Repository
          </Typography>

          <Box component="form" sx={{ mt: 1, width: 450 }}>
            <FormControl fullWidth>
              <InputLabel id="label" sx={{ mt: 0.6 }}>
                Select Repository
              </InputLabel>
              <Select
                labelId="label"
                id="select"
                value={orgs.find(item => item.id === selectedTargetRepoId)?.repoName || ''}
                label="Select Repo Name"
                onChange={handleChange}
              >
                {orgs.map((item) => (
                  <MenuItem key={item.id} value={item.repoName}>
                    {item.repoName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {visible && (
              <FormControl margin="normal" fullWidth>
                <InputLabel id="label" sx={{ mt: 0.6 }}>
                  Select Branch
                </InputLabel>
                <Select
                  labelId="label"
                  id="select"
                  label="Select Branch Name"
                  onChange={handleBranchChange}
                >
                  {branches.map((branch) => (
                    <MenuItem key={branch} value={branch}>
                      {branch}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {visible && (
              <Box textAlign={"right"}>
                <Button
                  onClick={() => setOpenDialog(true)}
                  size="small"
                  sx={{ mt: 0 }}
                >
                  <Typography variant="caption">
                    + create new branch from main
                  </Typography>
                </Button>
              </Box>
            )}
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openloading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            <Dialog open={openDialog}>
              <DialogContent>
                <DialogContentText>
                  <Box component="form" noValidate sx={{ mt: 1, width: 250 }}>
                    <TextField
                      size="small"
                      required
                      fullWidth
                      label="Enter branch name"
                      name="branchName"
                      autoComplete="branchName"
                      autoFocus
                    />
                  </Box>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenDialog(false)}
                  size="small"
                  variant="contained"
                >
                  Back
                </Button>
                <Button size="small" variant="contained">
                  Create
                </Button>
              </DialogActions>
            </Dialog>
            <Button
              onClick={showTargetOptionCard}
              size="small"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Back
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
