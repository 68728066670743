import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CloudIcon from "@mui/icons-material/Cloud";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularSmall from "./ProgressBar";
import TargetOrgNameContext from "./targetOrgName-context";
import { useContext } from "react";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import PolylineTwoToneIcon from "@mui/icons-material/PolylineTwoTone";
import { Link } from "react-router-dom";
import ShowTargetOptionsContext from "./showTargetOptions-context";
import ShowSignInTargetContext from "./showSignInTarget-context";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function TargetOptions() {

  const {setShowTargetOptions} = useContext(ShowTargetOptionsContext);
  const {setShowSignInTarget} = useContext(ShowSignInTargetContext);

  const showSFSignInTargetField = () => {
    setShowTargetOptions(false);
    setShowSignInTarget(true);
  };

  const showCSSignInTargetField = () => {
    setShowTargetOptions(false);
    setShowSignInTarget(false);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <CreateNewFolderIcon />
          </Avatar>
          <Typography sx={{ mb: 2 }} component="h1" variant="h5">
            Select Target
          </Typography>

          <Box component="form" sx={{ mt: 1, width: 450 }}>
            <Box sx={{ p: 0 }}></Box>
            <Divider light />
            <Box sx={{ p: 2 }}>
              <Stack alignItems="flex-start" direction="column" spacing={2}>
                <Button
                 onClick={showSFSignInTargetField}
                  startIcon={<CloudIcon />}
                  sx={{ textTransform: "capitalize" }}
                  size="large"
                >
                  Salesforce Organization
                </Button>
                <Button
                onClick={showCSSignInTargetField}
                  startIcon={<PolylineTwoToneIcon />}
                  sx={{ textTransform: "capitalize" }}
                  size="large"
                >
                  Source Control
                </Button>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
