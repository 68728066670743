import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import PackageList from './PackageListNewOne';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';


export default function PackageContent() {
  return (
    
     
        
          
          <Box
          sx={{
          marginTop: -4,
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 0,
          marginBottom: -2,
        }}
        >
          <Typography sx={{
                marginLeft: 0,
                marginBottom: 1,
                fontSize:20,
                mt:1,
                ml:1,
                textAlign:'Justify',
              }}>
                List of 2GP Packages 
          </Typography>
          <PackageList />
        </Box>
        
            
      
      
      
    
  );
}
