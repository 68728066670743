import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import FullWidthTabs from './Tabs';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import PackageTabs from './PackageTabs';
import MetadataComparisonTabs from './Tabs';
import TabsNew from './TabsNewOne';

export default function Content1() {
  return (
    
     
        
          <Box sx={{mt:-3}}>
            
            <TabsNew />
          </Box>
      
      
    
  );
}
