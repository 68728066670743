import * as React from 'react';

const PackIdContext = React.createContext({
  
  
  setPackId: ""


})

export default PackIdContext;
