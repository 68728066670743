import * as React from 'react';

const ShowTargetOptionsContext = React.createContext({
  
    setShowTargetOptions : true,
  

})

export default ShowTargetOptionsContext;
