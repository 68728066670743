import * as React from 'react';

const TargetBranchContext = React.createContext({
  
  
  setTargetBranch: ""


})

export default TargetBranchContext;
