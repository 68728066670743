import * as React from 'react';
import { useEffect } from 'react';
import { useContext } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloudIcon from '@mui/icons-material/Cloud';
import axios from 'axios';
import { useUser } from './UserContext';
import ProjectContext from "./project-context";
import CircularIndeterminate from './ProgressBar';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import {Link} from 'react-router-dom';
import OrgTypeContext from './OrgType-Context';


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const types = [
  'HTTPS',
  'SSH',
  
];






export default function SelectGitHubAuthType() {

    


    const handleChange = (event) => {
      const selectedType = event.target.value;
      console.log(selectedType);

      
      
      };

    

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 2, bgcolor: 'secondary.main' }}>
            <CloudIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Select Authentication Type
          </Typography>
          <Box component="form"  sx={{mt: 2, width:450}}>
          <FormControl  component={Link} to="/HttpsGitRepo" fullWidth>
        <InputLabel id="demo-simple-select-label">Select</InputLabel>
        <Select 
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          
          label="Choose"
          onChange={handleChange}
        >
          {types.map((orgType) => (
            <MenuItem
              value={orgType}
            >
              {orgType}
            </MenuItem>
          ))}
        </Select>
        
      </FormControl>
      
      </Box>
        </Box>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={1} sx={{ml:0, mt:33  }}>
        <Button
            component={Link} to="/GitHubProjectList"
            variant="contained" 
            size="medium" 
            fullWidth
            >
             Back
            </Button>
        </Grid>
        </Grid>
       
      </Container>
    </ThemeProvider>
  );
}
