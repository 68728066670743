import React from "react";
import logo from './Images/logo.png';



export default function Logo(){
    return(
        <div>
            <img src={logo} alt="pic" />
        </div>
    )
}