import * as React from 'react';

const ProjectContext = React.createContext({
  
  
  setProject: ""


})

export default ProjectContext;
