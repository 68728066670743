import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import config from './Config';

const UserContext = createContext();

export const UserProvider = ({ children, id }) => {
  
  const [userData, setUserData] = useState();

  useEffect(() => {
    // Fetch data using Axios with a POST request
    const fetchData = async () => {
      const userParam = { id };
      console.log("FetchuserInfo", userParam);

      try {
        const response = await axios.post(
          config.basePath+"/User/userinfo",
          userParam
        );
        setUserData(response.data);
        console.log('userDatainUserProvider',response.data);
        
     } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the fetchData function when the component mounts
  }, [id]); // Empty dependency array ensures useEffect runs once after the initial render



  useEffect(() => {
    console.log(userData);
  }, [userData]);

  return (
    <UserContext.Provider 
      value={userData}>{children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
