import * as React from 'react';

const OrgTypeContext = React.createContext({
  
  
  setOrgType: ""


})

export default OrgTypeContext;
