
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { useContext } from "react";
import SelectedPackVersionContext from './SelectedPackVersion-Context';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop"; 
import { connect } from 'react-redux';
import config from './Config';


const columns = [
  {
    field: 'packageName',
    headerName: 'Package Name',
    width: 180,
    
  },
  {
    field: 'packageId',
    headerName: 'Package ID',
    width: 200,
  },
  {
    field: 'projectName',
    headerName: 'Project Name',
    width: 150,
  },
  {
    field: 'devHubOrg',
    headerName: 'Dev Hub Org',
    width: 320,
    
  },
  {
    field: 'createdDate',
    headerName: 'Created Date',
    width: 150,
    valueFormatter: params => new Date(params?.value).toLocaleString(),
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    width: 150,
  },
  
];


const mapStateToProps = state => ({
  id: state.session.id
});

export default connect(mapStateToProps)(PackageList);



function PackageList(id) {

    const [packages, setPackages] = React.useState([]);
    const {setSelectedPackVersionValue} = useContext(SelectedPackVersionContext);
    const [selectedEntities, setSelectedEntities] = React.useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState();
    const [openDeleteSuccessDialog, setOpenDeleteSuccessDialog] = React.useState();
    const [responseData,setResponseData] = React.useState();
    const [openloading, setOpenloading] = React.useState(false);


  useEffect(() => {
    // Fetch data using Axios with a GET request
    console.log('id:',id.id)
    const userId = { id: id.id };

    axios.post(config.basePath+'/PackageVersions/allPackageVersions', userId)
      .then((response) => {
        const posts = response.data.map(obj => ({id: obj.id , packageName: obj.packageName , packageId: obj.packageVersionID , projectName: obj.projectName , devHubOrg: obj.devHubUsername , createdDate: obj.createdDate, createdBy: obj.userName}));
        setPackages(posts);
        
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [id]);

  useEffect(() => {
    if (responseData) {
      setOpenloading(false);
      setOpenDeleteSuccessDialog(true);
      
      const timeoutId = setTimeout(() => {
        setOpenDeleteSuccessDialog(false);
        window.location.reload(); // Reloading the page after 3 seconds
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [responseData]);

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      setOpenDeleteDialog(false);
      setOpenloading(true);
      const response = await axios.post(
        config.basePath+"/Delete/deletePackages",
        selectedEntities
      );
      console.log(selectedEntities);
      console.log("Request Sent");
      setResponseData(response);
      // Handle success or failure
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={{ height: 500, width: '100%', fontSize:8 }}>
      <DataGrid
        rows={packages}
        columns ={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
              
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        pageSizeOptions={[10]}
        rowHeight={44}
        checkboxSelection
        disableRowSelectionOnClick

        onRowSelectionModelChange={(id) => {
          const selectedIDs = new Set(id);
          const selectedCheckbox = packages.filter((row) =>
            selectedIDs.has(row.id)
          );
          setSelectedPackVersionValue(selectedCheckbox);
          setSelectedEntities(selectedCheckbox);
          console.log(selectedCheckbox);
        }}
        
        
          

      />
      <Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
    
            <Grid item xs={6}>
              <Button
             
              type="submit"
              size="small"     
              variant="contained"
              disabled={selectedEntities.length === 0}
              sx={{ mt: 1 }}
              onClick={() => setOpenDeleteDialog(true)}
            >
              Delete
            </Button>
            </Grid>
      
      
          <Grid item xs={6} sx={{textAlign:'right'}}>
            <Button
             
              type="submit"
              size="small"
              disabled={selectedEntities.length === 0}
              component={Link} to="/InstallPackage"
              variant="contained"
           
              sx={{ mt: 1 }}
            >
              Install
            </Button>

            </Grid>

        
    </Grid>
    <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openloading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
        <Dialog
          open={openDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Alert!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are your sure you want to delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDelete} color="success" size="small" autoFocus variant="contained">
              Yes
            </Button>
            <Button onClick={() => setOpenDeleteDialog(false)} size="small" autoFocus variant="contained">
              No
            </Button>
          </DialogActions>
        </Dialog>
        
        <Dialog
          open={openDeleteSuccessDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Successfully deleted.
            </DialogContentText>
          </DialogContent>
          
        </Dialog>
    </Box>
    </Box>
  );
}
