import { json } from 'react-router-dom';
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, CHANGE_STATE, CHANGE_USERID, SET_COMPARISON_DATA } from './sessionActions';
import config from './Config';

const initialState = {
  id: localStorage.getItem('id'),
  loading: false,
  error: null,
  isAuth: localStorage.getItem('isAuth'),
  comparisonData: JSON.stringify(localStorage.getItem('comparisonData')),
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_USERID:
      return {...state, id: action.payload};
    case CHANGE_STATE:
        return {...state, isAuth: action.payload};
    case LOGIN_REQUEST:
      return { ...state, loading: true, error: null };
    case LOGIN_SUCCESS:
      return { ...state, loading: false, id: action.payload, error: null, isAuth: true };
    case LOGIN_FAILURE:
      return { ...state, loading: false, error: action.payload, isAuth: false};
    case LOGOUT:
      return { ...state, loading: false, error: null, isAuth: false, id: null };
    case SET_COMPARISON_DATA:
      return { ...state, comparisonData: action.payload };
    default:
      return state;
  }
};

const resetInitialStateAfterTimeout = () => {
  const inactivityDuration = 30 * 60 * 1000; // 30 minutes in milliseconds

  let activityTimer; 

  const resetStateAndRedirect = () => {
    
    initialState.id = null;
    initialState.loading = false;
    initialState.error = null;
    initialState.isAuth = null;

   
    localStorage.removeItem('id');
    localStorage.removeItem('isAuth');

  
    
      window.location.href = config.homePagePath;
  

 

    console.log('Initial conditions reset after 30 minutes of inactivity');
  };

 

  const resetTimer = () => {
    if (activityTimer) {
      clearTimeout(activityTimer);
    }
    activityTimer = setTimeout(resetStateAndRedirect, inactivityDuration);
  };

  
  const handleUserActivity = () => {
    resetTimer(); 
  };

  document.addEventListener('mousemove', handleUserActivity);
  document.addEventListener('keydown', handleUserActivity);

  resetTimer();

  
};

resetInitialStateAfterTimeout();



export default sessionReducer;
