import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ForceForgeIoLogo from './Images/forceforgeioLogo';
import axios from "axios";
import {Link} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginSuccess } from './sessionActions';
import config from './Config';
import {SignInWithGoogle} from "./GoogleAuthantication/AuthWithGoogle";





// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function AccountSignInPage() {

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const dispatch = useDispatch();
  
  const signIn = async (e) => {
    e.preventDefault();
    try {
      const user = { email: email, password: password };
      axios.defaults.timeout = config.requestTimeout; const response = await axios.post(config.basePath+"/User/login", user);
      
      console.log(response.data.id); 

      
      
      dispatch(loginSuccess(response.data.id));
    } catch (error) {
      console.error(error);
    }
   
  };


  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundColor: '#4481eb', // Hexadecimal color for sky blue
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
         
        >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              marginTop={20}
            >
              <ForceForgeIoLogo width={200} height={200} />
          </Box>
            <Box display="flex"
            marginTop={-2}
            alignItems="center"
            justifyContent="center">
          <Typography sx={{
                      color: 'white',
                      width: '70%', 
                      textAlign:'justify'
                    }} variant="subtitle1"
                    >
                      If your goal is to build an app and distribute it on AppExchange, Force Forge will do the both. 
                      Force Forge provides a container that you fill with metadata, 
                      and it holds the set of related features, customizations, and schema that make up your app.
          </Typography>
          </Box>
          
      </Grid>
        
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 12,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" onSubmit={signIn} noValidate  sx={{ mt: 1, width:400}}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              
              <Button
              size='large'
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 1 }}
                
              >
                Sign In
              </Button>
              <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              paddingBottom:1,
              alignItems: "center",

            }}
          >
            <Typography variant="button" display="block">
              OR
            </Typography>
            </Box>
            <Box sx={{ display: 'flex',
                    justifyContent: 'center',
                    mb:1}}>
              <SignInWithGoogle />
         
          </Box>
              
             <Grid container>
                <Grid item xs>
                  <Button component={Link} to="/ForgotPassword" autoFocus variant="text">
                    Forgot password?
                  </Button>
                </Grid>
              </Grid>
              
              <Grid container>
                <Grid item xs={12} textAlign='center' sx={{mt: 0}}>
                  <Button component={Link} to="/AccountSignUpPage" autoFocus variant="text">
                    Don't have an account? Sign Up
                  </Button>
                </Grid>
              </Grid>
            </Box>
          
          </Box>
          
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}