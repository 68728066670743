import * as React from 'react';

const ShowSourceOptionsContext = React.createContext({
  
    setShowSourceOptions : true,
  

})

export default ShowSourceOptionsContext;
