
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {Link} from 'react-router-dom';
import ConnectRepoButton from './ConnectRepoButton';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { connect } from 'react-redux';
import config from './Config';


const columns = [
  
  {
    field: 'repo',
    headerName: 'REPOSITORY',
    width: 250,
    
  },

  {
    field: 'repourl',
    headerName: 'REPOSITORY URL',
    width: 350,
    
  },
 
  {
    field: 'gitHubUsername',
    headerName: 'GITHUB ACCOUNT',
    width: 300,
    
  },
  
  {
    field: 'authBy',
    headerName: 'AUTHORIZED BY',
    width: 200,
    
  },
  
];

const mapStateToProps = state => ({
  id: state.session.id
});

export default connect(mapStateToProps)(GitHubProjectList);



function GitHubProjectList(id) {
    
    
    
    const [orgs, setOrgs] = React.useState([]);
    const [selectedEntities, setSelectedEntities] = React.useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState();
    const [openDeleteSuccessDialog, setOpenDeleteSuccessDialog] = React.useState();
    const [responseData,setResponseData] = React.useState();
    const [openloading, setOpenloading] = React.useState(false);

  useEffect(() => {
    // Fetch data using Axios with a GET request
    console.log('id:',id.id)
    const userId = { id: id.id };

    axios.post(config.basePath+'/Git/allRepositories', userId)
      .then((response) => {
        const posts = response.data.map(obj => ({id: obj.id , repo: obj.repoName , repourl: obj.repoURL, gitHubUsername: obj.gitHubUsername, authBy: obj.userName}));
        setOrgs(posts);
        
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  useEffect(() => {
    if (responseData) {
      setOpenloading(false);
      setOpenDeleteSuccessDialog(true);
      
      const timeoutId = setTimeout(() => {
        setOpenDeleteSuccessDialog(false);
        window.location.reload(); // Reloading the page after 3 seconds
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [responseData]);

 


  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      setOpenDeleteDialog(false);
      setOpenloading(true);
      const response = await axios.post(
        config.basePath+"/Delete/deleteGitHubRepos",
        selectedEntities
      );
      console.log(selectedEntities);
      console.log("Request Sent");
      setResponseData(response);
      // Handle success or failure
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <Box
          sx={{
            marginTop: -4,
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 0,
            marginBottom: -2,
          }}
          >
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={10} sx={{  }}>
            <Typography sx={{
                  marginLeft: 0,
                  marginBottom: 1,
                  fontSize:20,
                  mt:1,
                  ml:1,
                  textAlign:'Justify',
                }}>
                  List of GitHub Repositories
            </Typography>
            </Grid>
            <Grid item xs={2} sx={{ mt: 0, ml: 0}}>
              
              <ConnectRepoButton />
            
            </Grid>
            </Grid>
            <Box sx={{ height: 500, width: '100%', fontSize:8 }}>
             
      <DataGrid
        rows={orgs}
        
        columns={columns}
        
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
              
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pageSizeOptions={[10]}
          rowHeight={44}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(id) => {
            const selectedIDs = new Set(id);
            const selectedCheckbox = orgs.filter((row) =>
              selectedIDs.has(row.id)
            );
            setSelectedEntities(selectedCheckbox);
            console.log(selectedCheckbox);
          }}
        
       
        
      />
      
            <Button
             onClick={() => setOpenDeleteDialog(true)}
              type="submit"
              size="medium"     
              variant="contained" 
              disabled={selectedEntities.length === 0}
              sx={{ mt: 1, }}
            >
              Delete
            </Button>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openloading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
        <Dialog
          open={openDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Alert!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are your sure you want to delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDelete} color="success" size="small" autoFocus variant="contained">
              Yes
            </Button>
            <Button onClick={() => setOpenDeleteDialog(false)} size="small" autoFocus variant="contained">
              No
            </Button>
          </DialogActions>
        </Dialog>
        
        <Dialog
          open={openDeleteSuccessDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Successfully deleted.
            </DialogContentText>
          </DialogContent>
          
        </Dialog>
      
    </Box>
    </Box>
  );
}
