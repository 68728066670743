import * as React from 'react';

const SourceBranchContext = React.createContext({
  
  
  setSourceBranch: ""


})

export default SourceBranchContext;
