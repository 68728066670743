import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import SourceOrgNameContext from '../../sourceOrgName-context';
import TargetOrgNameContext from '../../targetOrgName-context';
import { useContext } from "react";
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { Container } from '@mui/material';
import SelectedCheckboxContext from '../../SelectedCheckbox-Context';
import ComparisonOrgsDataContext from '../../GlobalValues/comparisonorgsdata-context';
import ComparisonOrgsDataBackdropContext from '../../GlobalValues/comparisonorgsdatabackdrop-context';
import OrgFilesForAnalysisContext from '../../GlobalValues/orgfilesforanalysis-context';

import Typography from "@mui/material/Typography";
import config from '../../Config';

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from '@mui/material/CircularProgress';
import SelectedfilestosfanalyzerContext from '../../GlobalValues/selectedfilestosfanalyzer-context';

const columns = [
  
  {
    field: 'fileName',
    headerName: 'File Name',
    width: 450,
    
  },
  
  {
    field: 'metadataType',
    headerName: 'Metadata type',
    width: 400,
    
  },
  {
    field: 'referenceObject',
    headerName: 'Reference Object',
    width: 270,
  },
  
  
];



export default function SalesforceCodeAnalyzerFileSelectionTable() {

  const { targetOrgName } = useContext(TargetOrgNameContext);
  const { sourceOrgName } = useContext(SourceOrgNameContext);
  const {setSelectedCheckbox} = useContext(SelectedCheckboxContext);
  const {setSelectedFilesToSFAnalyzer} = useContext(SelectedfilestosfanalyzerContext);
  const [selectedRows, setSelectedRows] = useState([]);
  const [responseResult, setResponseResult] = useState([]);
  
  const {comparisonOrgsDataBackdrop} = useContext(ComparisonOrgsDataBackdropContext);
  const {orgfilesforanalysis} = useContext(OrgFilesForAnalysisContext);

  
  return (
    <Container sx={{marginLeft:-3}}>
     <Backdrop
      sx={{ 
        color: '#fff', 
        zIndex: (theme) => theme.zIndex.drawer + 1, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        backgroundColor: 'rgba(0, 0, 0, 0.8)'  
      }}
      open={comparisonOrgsDataBackdrop}
    >
      <Box 
        sx={{ 
          textAlign: 'center', 
          padding: 3, 
          borderRadius: 2, 
          backgroundColor: 'white', 
          backdropFilter: 'blur(5px)',  
          boxShadow: 1  
        }}
      >
        <Typography variant="h7" sx={{ marginBottom: 2, color: 'black' }}>
          Preparing Comparison. It will take 3-4 mins. 
          Please wait...
        </Typography>
        <Box sx={{ marginTop: 2}}>
        <CircularProgress color='secondary' />
        </Box>
      </Box>
    </Backdrop>
      
        
    <Box sx={{ height: 500, width: '154%', fontSize:6 }}>
      <DataGrid
        rows={orgfilesforanalysis}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        pageSizeOptions={[5]}
        rowHeight={30}
        checkboxSelection
        disableRowSelectionOnClick
        
        
        onRowSelectionModelChange={(id) => {
          const selectedIDs = new Set(id);
          const selectedCheckbox = orgfilesforanalysis.filter((row) =>
            selectedIDs.has(row.id)
          );
          setSelectedFilesToSFAnalyzer(selectedCheckbox);
          console.log('SELECTED CHECK BOXES IN GRID PAGE',selectedCheckbox);
        }}
        
        
          
       
       
        
      />
       
    </Box>
    
    
   
    
    </Container>
  );
}
