
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {Link} from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { connect } from 'react-redux';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import { useNavigate } from 'react-router-dom';
import { useContext } from "react";
import SourceRepoIdContext from "../GlobalValues/sourcerepoid-context";
import SourceSFOrgIdContext from "../GlobalValues/sourcesforgid-context";
import TargetRepoIdContext from "../GlobalValues/targetrepoid-context";
import TargetSFOrgIdContext from "../GlobalValues/targetsforgid-context";
import config from '../Config';
import ComparisonOrgsDataContext from '../GlobalValues/comparisonorgsdata-context';
import ComparisonOrgsDataBackdropContext from "../GlobalValues/comparisonorgsdatabackdrop-context";
import SourceOrgNameContext from "../sourceOrgName-context";
import TargetOrgNameContext from "../targetOrgName-context";
import SelectedCheckboxContext from '../SelectedCheckbox-Context';





const mapStateToProps = state => ({
  id: state.session.id
});

export default connect(mapStateToProps)(DeploymentHistoryMainContant);



function DeploymentHistoryMainContant(id) {


  const columns = [
  
    {
      field: 'sourceName',
      headerName: 'Source Username',
      width: 280,
      
    },
   
    {
      field: 'targetName',
      headerName: 'Target Username',
      width: 280,
      
    },
  
    {
      field: 'deployDate',
      headerName: 'Date/Time',
      width: 250,
      valueFormatter: params => new Date(params?.value).toLocaleString(),
      
    },
    
    {
      field: 'deployedBy',
      headerName: 'Depolyed By',
      width: 250,
      
    },
  
    {
      field: 'action',
      headerName: 'Action',
      width: 60,
      renderCell: (params) => (
        <Button
        
       

      >
        <VisibilitySharpIcon />
      </Button>
      ),
    },
    
  ];

  const { setSourceOrgName } = useContext(SourceOrgNameContext);
  const { setTargetOrgName } = useContext(TargetOrgNameContext);
  const {setSelectedCheckbox} = useContext(SelectedCheckboxContext);

    
  const {setComparisonOrgsDataBackdrop} = useContext(ComparisonOrgsDataBackdropContext);

    const {setComparisonOrgsData} = useContext(ComparisonOrgsDataContext);
    const [rowSelect, setRowSelect] = React.useState([]);
    const [orgs, setOrgs] = React.useState([]);
    const [selectedEntities, setSelectedEntities] = React.useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState();
    const [openDeleteSuccessDialog, setOpenDeleteSuccessDialog] = React.useState();
    const [responseData,setResponseData] = React.useState();
    const [openloading, setOpenloading] = React.useState(false);
    const [viewComparisonId, setViewComparisonId] = React.useState();
    const [viewButtonEnabled, setViewButtonEnabled] = useState(false);

    const navigate = useNavigate();

     //setters of Global Variables
  const {setSourceRepoId} = useContext(SourceRepoIdContext);
  const {setSourceOrgId} = useContext(SourceSFOrgIdContext);
  const {setTargetRepoId} = useContext(TargetRepoIdContext);
  const {setTargetOrgId} = useContext(TargetSFOrgIdContext);

  useEffect(() => {
    // Fetch data using Axios with a GET request
    setOpenloading(true);
    console.log('id:',id.id);
   
    const userId = { id: id.id };
    axios.post(config.basePath+'/Deploy/DeploymentHistory', userId)
      .then((response) => {
        const posts = response.data.map(obj => ({id: obj.id , sourceName: obj.sourceOrg, targetName: obj.targetOrg,  deployDate: obj.deployDate, deployedBy: obj.deployedBy}));
        setOrgs(posts);
        setOpenloading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);


  useEffect(() => {
    if (responseData) {
      setOpenloading(false);
      setOpenDeleteSuccessDialog(true);
      
      const timeoutId = setTimeout(() => {
        setOpenDeleteSuccessDialog(false);
        window.location.reload(); // Reloading the page after 3 seconds
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [responseData]);



  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      setOpenDeleteDialog(false);
      setOpenloading(true);
      const response = await axios.post(
        config.basePath+"/Delete/deleteDeploymentHistoryFiles",
        selectedEntities
      );
      console.log(selectedEntities);
      console.log("Request Sent");
      setResponseData(response);
      // Handle success or failure
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };



const handleViewSubmit = async (params) => {
  
  
  console.log('ROWIDin VIEW SUBMIT:', params.row.id);
  const DeployMetaData = { 
    id: params.row.id,
    userId: id.id
  };
  
  navigate('/DeploymentHistoryEntityPage');
  setComparisonOrgsDataBackdrop(true);

  try {
    const axiosConfig = {
      timeout: 1000000, // 10 seconds
    };
    console.log('ROWID:', params.row.id);
    const response = await axios.post(config.basePath+'/Deploy/viewDeployedMetaData', DeployMetaData, axiosConfig);
    const deployedMetaData = response.data.filesToDeploy;
    setSourceOrgName(response.data.sourceOrg);
    setTargetOrgName(response.data.targetOrg);
    console.info("deployedMetaData-->" + deployedMetaData);
    const posts = deployedMetaData.map(obj => {
      const fileName = obj.fileName.split('/');
      const refineFileName = fileName[fileName.length - 1].split(".")[0];
      return {
        id: obj.filesToDeployId,
        fileName: refineFileName,
        metadataType: obj.metadataType,
        differenceType: obj.differenceType,
        
      };
    });

    setSelectedCheckbox(posts);
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    setComparisonOrgsDataBackdrop(false);
  }
};


  return (
    <Box
          sx={{
            marginTop: -4,
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 0,
            marginBottom: -2,
          }}
          >
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={10} sx={{  }}>
            <Typography sx={{
                  marginLeft: 0,
                  marginBottom: 1,
                  fontSize:20,
                  mt:1,
                  ml:1,
                  textAlign:'Justify',
                }}>
                  Deployment History
            </Typography>
            </Grid>
            </Grid>
            <Box sx={{ height: 500, width: '100%', fontSize:8 }}>
      <DataGrid
        rows={orgs}
        
        columns={columns}
        
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
              
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pageSizeOptions={[10]}
          rowHeight={44}
          checkboxSelection
          onRowClick={handleViewSubmit}
          onRowSelectionModelChange={(id) => {
            const selectedIDs = new Set(id);
            const selectedRow = orgs.filter((row) =>
              selectedIDs.has(row.id)
            );
            setSelectedEntities(selectedRow);
            console.log(selectedRow);
          }}
        disableRowSelectionOnClick
        
      />
      
            <Button
             onClick={() => setOpenDeleteDialog(true)}
              type="submit"
              size="medium"     
              variant="contained" 
              disabled={selectedEntities.length === 0}
              sx={{ mt: 1, }}
            >
              Delete
            </Button>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openloading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
        <Dialog
          open={openDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Alert!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are your sure you want to delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDelete} color="success" size="small" autoFocus variant="contained">
              Yes
            </Button>
            <Button onClick={() => setOpenDeleteDialog(false)} size="small" autoFocus variant="contained">
              No
            </Button>
          </DialogActions>
        </Dialog>
        
        <Dialog
          open={openDeleteSuccessDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Successfully deleted.
            </DialogContentText>
          </DialogContent>
          
        </Dialog>
      
    </Box>
    </Box>
  );
}
