import * as React from 'react';
import Grid from '@mui/material/Grid';
import {Link} from 'react-router-dom';
import DataGridCompareDeploy from '../Grid';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";


export default function ComparisonHistoryEntityPage() {
  return (
   
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        
        <Grid item xs={9} sx={{ mt: -3 }}>
        <Typography component="h1" variant="h6">
            COMPARISON HISTORY
          </Typography>
        </Grid>
        <Grid container justifyContent="flex-end" item xs={3} sx={{ mt: -3, mr: 0, pr:1 }}>
        
                <Button
                component={Link} to="/ComparisonHistoryMainContent"
                variant="contained" 
                size="small" 
                
                >
                  Back</Button>
                
              
           
        </Grid>
        <Grid item xs={8.3}>
          <DataGridCompareDeploy />
        </Grid>
      </Grid>
   
  );
}