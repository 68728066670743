import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import axios from 'axios';
import DevHubOrgList from './DevHubOrgListNew';
import CloudIcon from '@mui/icons-material/Cloud';
import Divider from '@mui/material/Divider';
import SignIn from './SignIn';
import SignInTarget from './SignInTarget';
import CompareProjects from './CompareProjects';


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function TabsNew() {

 

  return (
    
      
    <Grid container spacing={0} >
    <Grid item xs={6}>
      <Box
        sx={{
          marginTop: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginRight: 15,
          
        }}
      >
        
        <SignIn />
        
          
        
      </Box>
      </Grid>
      <Grid item xs={6}>



      <Box
        sx={{
          marginTop: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginRight: 4,
        }}
      >
          <SignInTarget />
      </Box>



      </Grid>
        <Box width={220} align="center" position="fixed" sx={{mt:45, ml:58, mr:58}}>
                <CompareProjects />
        </Box>
      </Grid>
      
);
}
