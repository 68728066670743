import * as React from 'react';

const SfContext = React.createContext({
 
  setIsSalesforceOrg : ""
  

})

export default SfContext;
