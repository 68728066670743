import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloudIcon from '@mui/icons-material/Cloud';
import axios from 'axios';
import CompareIcon from '@mui/icons-material/Compare';
import FolderIcon from '@mui/icons-material/Folder';
import Logo from './logo';
import Logo2 from './logo2';
import {Link} from 'react-router-dom';

const defaultTheme = createTheme();

export default function Content0() {

  return (
    <ThemeProvider theme={defaultTheme} >
      <Grid container spacing={0} >
      <Grid item xs={6}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: -4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width:'full',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <CompareIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
          Create Your First Metadata Comparison Pair
          </Typography>
          <Box marginLeft={0} marginRight={0} marginTop={0}>
          <Logo />
          </Box>
          <Box noValidate sx={{ mt: 1, width:400 }}>
            
            <Button component={Link} to="/MetaDataComparisonMain"
              type="submit"
              fullWidth
              variant="contained"
              size="large"
            >
              Create a New Metadata Comparison Pair
            </Button>
          </Box>
        </Box>
      
      
      </Grid>
      <Grid item xs={6}>
      <Container>
        <CssBaseline />
        <Box
          sx={{
            marginTop: -4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width:'full',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <FolderIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
          Create Your First Managed Package
          </Typography>
          <Box marginLeft={0} marginRight={0} marginTop={0}>
          <Logo2 />
          </Box>
          <Box noValidate sx={{ mt: 1, width:400 }}>
            <Button component={Link} to="/Content1"
              type="submit"
              fullWidth
              variant="contained"
              size="large"
            >
              Create a New Package
            </Button>
          </Box>
        </Box>
        </Container>
        </Grid>
        </Grid>
    </ThemeProvider>
  );
}
