import * as React from 'react';

const TargetRepoIdContext = React.createContext({
  
  
  setTargetRepoId: ""


})

export default TargetRepoIdContext;
