import * as React from 'react';
import RowAndColumnSpacing from './Compare&Deploy';



export default function CompareContent() {
    return (
      
                <RowAndColumnSpacing />
                
              
    );
  }