import { useState, useEffect } from 'react';
import PaperBase from "./Paperbase";
import * as React from 'react';
import { useContext } from "react";
import MainPaperBase from './MainPaperBase';
import MetaDataComparisonMain from './MetaDataComparisonMain';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setAuthState } from './sessionActions';


export function AppRouter() {
  const dispatch = useDispatch();

  const isAuth = useSelector((state) => state.session.isAuth);

  useEffect(() => {
    const isAuthStored = localStorage.getItem('isAuth');
    console.info('isAuthStoredValueinAPp Router:',isAuthStored);
      dispatch(setAuthState(JSON.parse(isAuthStored)));
 
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem('isAuth', isAuth);
    console.log('isAuthParsed',isAuth);
  }, [isAuth]);

  console.info('In APP ROUTER FILE:',isAuth);

  return isAuth ? (
    <div>
      <PaperBase />
    </div>
  ) : (
    <div>
      <MainPaperBase />
    </div>
  );
}

