import * as React from 'react';
import { useEffect } from 'react';
import { useContext } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloudIcon from '@mui/icons-material/Cloud';
import axios from 'axios';
import { useUser } from './UserContext';
import ProjectContext from "./project-context";
import config from './Config';
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

function Copyright(props) {
  return 
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function CreateProject() {

    const [visible, setVisible] = React.useState(false);
    const [projectname, setProjectName] = React.useState('');
    const userData = useUser();
    const {setProject} = useContext(ProjectContext);
    const [responseProject, setResponseProject] = React.useState("");
    const [projectComplete, setProjectComplete] = React.useState("");
    const [openloading, setOpenloading] = React.useState(false);
    const [openProjectResponseDialog, setOpenProjectResponseDialog] = React.useState();


    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        setOpenloading(true);
        const project = { projectName: projectname , userName: userData.userName, userId: userData.id};
        axios.defaults.timeout = config.requestTimeout; const response = await axios.post(config.basePath+'/Projects/create', project); 
        setProject(projectname);
        setResponseProject(response);
        
      } catch (error) {
        console.error(error);
      }
    };


    useEffect(() => {
      if (responseProject) {
        setProjectComplete(responseProject.data); 
        setOpenloading(false);
        setOpenProjectResponseDialog(true);
      }
      const timeoutId = setTimeout(() => {
        setOpenProjectResponseDialog(false);
      
      }, 3000);

      return () => clearTimeout(timeoutId);
    }, [responseProject]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <CloudIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Create Project
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width:450 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Enter Project Name"
              name="projectName"
              autoComplete="projectName"
              autoFocus
              value={projectname}
              onChange={(e) => setProjectName(e.target.value)}
              
            />
            <Button
              onClick={() => setVisible(!visible)}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={projectComplete === "Project Created Successfully"}
            >
            Create Project
            </Button>
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
              open={openloading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
          <Dialog
          open={openProjectResponseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
             {projectComplete}
            </DialogContentText>
          </DialogContent>
          
        </Dialog>
        </Box>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
              open={openloading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
      </Container>
    </ThemeProvider>
  );
}
