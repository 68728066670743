import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {Link} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import axios from 'axios';
import SourceOrgsList from './SourceOrgsListNew';
import CloudIcon from '@mui/icons-material/Cloud';
import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OrgTypeContext from './OrgType-Context';
import { useContext } from "react";
import { useUser } from './UserContext';
import config from './Config';


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SourceOrgs() {

  const [username, setUsername] = React.useState('');
  const [responseRequest, setResponseRequest] = React.useState('');
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openInvalidDialog, setOpenInvalidDialog]= React.useState(false);
  const [openloading, setOpenloading] = React.useState(false);
  const [invalidResponse, setInvalidResponse] = React.useState('');
  const { orgType } = useContext(OrgTypeContext);
  const [isSourceOrg, setIsSourceOrg] = React.useState(); 
  const [isDevHubOrg, setIsDevHubOrg] = React.useState(); 
  const [isDeveloperOrg, setIsDeveloperOrg] = React.useState();
  const userData = useUser();
  
  useEffect(() => {
    console.log(userData);
  }, [userData]);
  
  useEffect(() => {
    if (orgType === "Salesforce Org" ) {
      setIsSourceOrg(true);
      setIsDevHubOrg(false);
      setIsDeveloperOrg(false);
    } else if (orgType === "Salesforce Dev Hub Org" ){
      setIsDevHubOrg(true);
      setIsSourceOrg(false);
      setIsDeveloperOrg(false);
    } else if (orgType === "Salesforce Developer Org" ){
      setIsDeveloperOrg(true);
      setIsSourceOrg(false);
      setIsDevHubOrg(false);
    }
  }, [orgType]);

  useEffect(() => {
    if (responseRequest ) {
      setOpenloading(false);
      setOpenDialog(true);
    }
  }, [responseRequest]);

  useEffect(() => {
    if (invalidResponse) {
      setOpenloading(false);
      setOpenDialog(false);
      setOpenInvalidDialog(true);
    }
  }, [invalidResponse]);

  
  const handleRefreshClick = () => {
    window.location.reload();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(isSourceOrg);
      console.log(isDevHubOrg);
      console.log(isDeveloperOrg);
      setOpenloading(true);
      const addOrgDevice = { userName: username, isSourceOrg: isSourceOrg, isDevHubOrg: isDevHubOrg, isDeveloperOrg: isDeveloperOrg, orgType: orgType, authBy: userData.userName, userId:userData.id};
      axios.defaults.timeout = config.requestTimeout; const response = await axios.post(config.basePath+'/OrgAuthorization/authOrgDevice', addOrgDevice); 

      // Convert the JSON string to a JavaScript object
      const parsedData = JSON.parse(JSON.stringify(response.data));
      setResponseRequest(parsedData);
      // Log the parsed JSON object
      console.log('Parsed JSON object:', parsedData);
      console.log(parsedData.user_code);
      
      console.log(addOrgDevice);
      console.log("Request Sent");
      console.log(response.data); // Handle success or failure

      if (response.data.includes("Already Exist")) {
        setInvalidResponse(response.data);
      } 
    } catch (error) {
      console.error(error);
    }
  };

  return (
    
      
    <ThemeProvider theme={defaultTheme}>
       
      <Container component="main" >
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <CloudIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Authorize Your Org
        </Typography>
        
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width:450}}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Enter Username"
            name="email"
            autoComplete="email"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Button
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            AUTHORIZE
          </Button>
        </Box>
        <Backdrop
       sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: 'flex',
        marginLeft: 30,
        alignItems: 'center',
        justifyContent: 'center',
      }}
        open={openloading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        <Dialog
        open={openDialog}
        
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Authorization!"}
        </DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center', marginBottom: '16px' }}>
              <span style={{ fontWeight: 'bold' }}>Use this device code:</span> {responseRequest.user_code} <br />
              <span style={{ fontWeight: 'bold' }}>Navigate to the following URL:</span> <a href={responseRequest.verification_uri} target="_blank" rel="noopener noreferrer">{responseRequest.verification_uri}</a> <br />
              <span style={{ fontWeight: 'bold' }}>to authorize the Salesforce organization.</span>
        </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRefreshClick} autoFocus variant="contained">
            Finish
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openInvalidDialog}
        
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Invalid Username!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This Username is already authorized. Plz try again with different Username.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRefreshClick} autoFocus variant="contained">
            Finish
          </Button>
        </DialogActions>
      </Dialog>
      </Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={1} sx={{ml:0, mt:24  }}>
        <Button
            
            component={Link} to="/SelectOrgType"
            variant="contained" 
            size="medium" 
            fullWidth
            >
             Back
            </Button>
        </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
      
);
}
