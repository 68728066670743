import * as React from 'react';
import {useState, useEffect } from 'react';
import { useContext } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloudIcon from '@mui/icons-material/Cloud';
import axios from 'axios';
import { useUser } from './UserContext';
import ProjectContext from "./project-context";
import PackageContext from './package-context';
import CircularIndeterminate from './ProgressBar';
import SelectedCheckboxContext from './SelectedCheckbox-Context';
import SelectedPackVersionContext from './SelectedPackVersion-Context';
import Backdrop from '@mui/material/Backdrop';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Link} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import config from './Config';
import { connect } from 'react-redux';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AddIcon from '@mui/icons-material/Add';


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const mapStateToProps = state => ({
  id: state.session.id
});

export default connect (mapStateToProps)(InstallPackage);

function InstallPackage(id) {

    const [visible, setVisible] = React.useState(false);
    const [targetUsernameId, setTargetUsernameId] = React.useState();
    const [responseInstallPackage, setResponseInstallPackage] = React.useState("");
    const {selectedPackVersionValue} = useContext(SelectedPackVersionContext);
    const [openloading, setOpenloading] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [orgs, setOrgs] = React.useState([]);
    const [selectedSourceSFId, setSelectedSourceSFId] = useState('');
    const userData = useUser();

    useEffect(() => {
      // Fetch data using Axios with a GET request
      console.log('id:',id.id)
      const userId = { id: id.id };
      axios.post(config.basePath+'/OrgAuthorization/allOrgs', userId)
        .then((response) => {
          const posts = response.data.map((obj) => ({
            id: obj.id,
            userName: obj.userName,
          }));
          setOrgs(posts);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }, []);


    const handleChange = async (event) => {
      const slectOrg = event.target.value;
      const selectedItem = orgs.find(item => item.userName === slectOrg);
      setSelectedSourceSFId(selectedItem.id);
      setTargetUsernameId(selectedItem.id);
      
      
    };
 
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        setOpenloading(true);
        const installPackageParams = { targetUsernameId: targetUsernameId, packageName:selectedPackVersionValue[0].packageName, installedBy: selectedPackVersionValue[0].createdBy, packageId: selectedPackVersionValue[0].packageId, projectName: selectedPackVersionValue[0].projectName, userId: userData.id};
        axios.defaults.timeout = config.requestTimeout; const response = await axios.post(config.basePath+'/Installation/installPackage', installPackageParams); 
        
        console.log("Request Sent");
        console.log(response.data); 
       
        setResponseInstallPackage(response.data);
      } catch (error) {
        console.error(error);
      }
    };


    useEffect(() => {
      if (responseInstallPackage) {
        setOpenloading(false);
        setOpenDialog(true); 
      }
    }, [responseInstallPackage]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
      
        <Box
          sx={{
            marginTop: 7,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 2, bgcolor: 'secondary.main' }}>
            <CloudIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Install Package
          </Typography>
          <Typography textAlign={'left'} variant="body1" sx={{ mt: 1 }}
           
          >
          Select SF Org where you want to install the package.
          </Typography>
          
          
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2, width: 450 }}>
            <FormControl fullWidth>
              <InputLabel id="label" sx={{ mt: 0.6 }}>
                Select Username
              </InputLabel>
              <Select
                labelId="label"
                id="select"
                value={orgs.find(item => item.id === selectedSourceSFId)?.userName || ''}
                label="Select Username"
                onChange={handleChange}
              >
                {orgs.map((item) => (
                  <MenuItem key={item.id} value={item.userName}>
                    {item.userName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{
                width: 430,
                color: 'darkblue',
                textAlign:'right',
                marginLeft: 'auto',
                mt:1,
              
                    }}>
            <Button   component={Link} to="/SelectOrgType" autoFocus variant="text">
                      <AddIcon /> Add Org 
            </Button>
          </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 2 }}
              
            >
            Install
            </Button>
            </Box>





          
          <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openloading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={openDialog}
        
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Installation Request Sent!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {responseInstallPackage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button component={Link} to="/Content0" autoFocus variant="contained">
            Finish
          </Button>
        </DialogActions>
      </Dialog>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
