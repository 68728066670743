import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import {Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import SourceOrgNameContext from './sourceOrgName-context';
import TargetOrgNameContext from './targetOrgName-context';
import { useContext } from "react";
import SourceRepoIdContext from './GlobalValues/sourcerepoid-context';
import TargetRepoIdContext from './GlobalValues/targetrepoid-context';
import SourceSFOrgIdContext from './GlobalValues/sourcesforgid-context';
import TargetSFOrgIdContext from './GlobalValues/targetsforgid-context';
import TargetBranchContext from './targetBranch-context';
import SourceBranchContext from './sourceBranch-context';
import ComparisonOrgsDataContext from './GlobalValues/comparisonorgsdata-context';
import ComparisonOrgsDataBackdropContext from "./GlobalValues/comparisonorgsdatabackdrop-context";
import { useUser } from './UserContext';
import config from './Config';





export default function CompareProjects() {

  //Setter
  const {setComparisonOrgsData} = useContext(ComparisonOrgsDataContext);
  const {setComparisonOrgsDataBackdrop} = useContext(ComparisonOrgsDataBackdropContext);

  //Getters
  const { targetOrgName } = useContext(TargetOrgNameContext);
  const { sourceOrgName } = useContext(SourceOrgNameContext);
  const { sourceOrgId } = useContext(SourceSFOrgIdContext);
  const { sourceRepoId } = useContext(SourceRepoIdContext);
  const { targetOrgId } = useContext(TargetSFOrgIdContext);
  const { targetRepoId } = useContext(TargetRepoIdContext);
  const { targetBranch } = useContext(TargetBranchContext);
  const { sourceBranch } = useContext(SourceBranchContext);
  
  //Contains the data of ForceForgeUser
  const userData = useUser();

  //Conditions to be checked to enable and disbale the START COMPARE Button
  const isStartCompareButtonEnabled = () => {
    const sourceCondition = (sourceOrgId !== 0 && sourceRepoId === 0) || (sourceOrgId === 0 && sourceRepoId !== 0);
    const targetCondition = (targetOrgId !== 0 && targetRepoId === 0) || (targetOrgId === 0 && targetRepoId !== 0);

    return sourceCondition && targetCondition;
  };


    const navigate = useNavigate();
  
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      const orgFile = { 
        sourceOrgName, 
        targetOrgName, 
        sourceSalesforceId: sourceOrgId, 
        targetSalesforceId: targetOrgId, 
        sourceRepoId, 
        targetRepoId, 
        targetBranch, 
        sourceBranch, 
        userId: userData.id,
        forceForgeUserName: userData.userName
      };
  
      navigate('/CompareContent');
      setComparisonOrgsDataBackdrop(true);
  
      try {
        const axiosConfig = {
          timeout: 1000000, // 10 seconds
        };
        
        const response = await axios.post(config.basePath+'/Comparison/compareOrgs', orgFile, axiosConfig);
        const comparisonOfFiles = response.data.orgFiles;
        console.info("comparisonOfFiles-->" + comparisonOfFiles);
        const posts = comparisonOfFiles.map(obj => {
          const fileName = obj.fileName.split('/');
          const refineFileName = fileName[fileName.length - 1].split(".")[0];
          return {
            id: obj.id,
            name: obj.fileName,
            fileName: refineFileName,
            filePath: obj.filePath,
            metadataType: obj.metaDataType,
            differenceType: obj.differenceType,
            referenceObject: obj.referenceObject,
          };
        });
  
        setComparisonOrgsData(posts);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setComparisonOrgsDataBackdrop(false);
      }
    };

    return (
      <Button 
      onClick={handleSubmit}
      type="submit"
      component={Link} 
      to="/CompareContent" 
      variant="contained" 
      color="success"
      size="large"
      fullWidth
      disabled={!isStartCompareButtonEnabled()}
      sx={{ mt: 10 }}
    >
      Start Compare 
    </Button>
    );
  }