import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import SourceOrgNameContext from './sourceOrgName-context';
import TargetOrgNameContext from './targetOrgName-context';
import { useContext } from "react";
import CodeDiffComponent from './Compare';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { Container } from '@mui/material';
import SelectedCheckboxContext from './SelectedCheckbox-Context';
import ComparisonOrgsDataContext from './GlobalValues/comparisonorgsdata-context';
import ComparisonOrgsDataBackdropContext from './GlobalValues/comparisonorgsdatabackdrop-context';
import { useDispatch } from 'react-redux';
import {setComparisonData} from './sessionActions';
import { useSelector } from 'react-redux';
import Typography from "@mui/material/Typography";
import config from './Config';

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from '@mui/material/CircularProgress';

const columns = [
  
  {
    field: 'fileName',
    headerName: 'File Name',
    width: 320,
    
  },
  
  {
    field: 'metadataType',
    headerName: 'Metadata type',
    width: 300,
    
  },
  {
    field: 'referenceObject',
    headerName: 'Reference Object',
    width: 270,
  },
  {
    field: 'differenceType',
    headerName: 'Difference type',
    width: 220,
  },
  
  
];



export default function DataGridCompareDeploy() {

  const { targetOrgName } = useContext(TargetOrgNameContext);
  const { sourceOrgName } = useContext(SourceOrgNameContext);
  const {setSelectedCheckbox} = useContext(SelectedCheckboxContext);
  const [orgData, setOrgData] = React.useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [responseResult, setResponseResult] = useState([]);
  const {setComparisonOrgsData} = useContext(ComparisonOrgsDataContext);
  const {comparisonOrgsData} = useContext(ComparisonOrgsDataContext);
  const {comparisonOrgsDataBackdrop} = useContext(ComparisonOrgsDataBackdropContext);

  
  return (
    <Container sx={{marginLeft:-3}}>
     <Backdrop
      sx={{ 
        color: '#fff', 
        zIndex: (theme) => theme.zIndex.drawer + 1, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        backgroundColor: 'rgba(0, 0, 0, 0.8)'  
      }}
      open={comparisonOrgsDataBackdrop}
    >
      <Box 
        sx={{ 
          textAlign: 'center', 
          padding: 3, 
          borderRadius: 2, 
          backgroundColor: 'white', 
          backdropFilter: 'blur(5px)',  
          boxShadow: 1  
        }}
      >
        <Typography variant="h7" sx={{ marginBottom: 2, color: 'black' }}>
          Preparing Comparison. It will take 3-4 mins. 
          Please wait...
        </Typography>
        <Box sx={{ marginTop: 2}}>
        <CircularProgress color='secondary' />
        </Box>
      </Box>
    </Backdrop>
       {comparisonOrgsData && comparisonOrgsData.length > 0 ? (
        <>
        
    <Box sx={{ height: 500, width: '154%', fontSize:6 }}>
      <DataGrid
        rows={comparisonOrgsData}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        pageSizeOptions={[5]}
        rowHeight={30}
        checkboxSelection
        disableRowSelectionOnClick
        
        
        onRowSelectionModelChange={(id) => {
          const selectedIDs = new Set(id);
          const selectedCheckbox = comparisonOrgsData.filter((row) =>
            selectedIDs.has(row.id)
          );
          setSelectedCheckbox(selectedCheckbox);
          console.log('SELECTED CHECK BOXES IN GRID PAGE',selectedCheckbox);
        }}
        
        
          onRowClick={(params) => {
          const { id } = params.row;
          const selectedRows = comparisonOrgsData.filter((row) => row.id === id);

          setSelectedRows(selectedRows);
          console.log(selectedRows);

          
          if (selectedRows.length > 0) {
            
            const fileName = selectedRows[0].name.split('/');
            const refineFileName = fileName[fileName.length - 1].split(".")[0];


            const orgFile = {filePath: selectedRows[0].name, fileName: refineFileName, metaDataType: selectedRows[0].metaDataType, sourceOrgName: sourceOrgName , targetOrgName: targetOrgName};
          
            axios.post(config.basePath+'/Comparison/getFile',orgFile)
              .then((response) => {
                // Handle the fetched data here
                console.log(response.data);
                setResponseResult(response.data);
                console.log(orgFile);
              })
              .catch((error) => {
                console.error('Error fetching data:', error);
              });
          }
        }}
       
       
        
      />
       
    </Box>
    
    <ReactDiffViewer oldValue={responseResult[0]} newValue={responseResult[1]} compareMethod={DiffMethod.WORDS_WITH_SPACE} splitView={true} styles={{diffContainer: {fontSize: '11px'},}}/>
    </>
    ) : (
      <p>There is something wrong in chosing source and target. Check and try again</p>
    )}
    </Container>
  );
}
