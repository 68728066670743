import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SignInTarget from './SignInTarget';
import RowAndColumnSpacing from './Compare&Deploy';
import CodeDiffComponent from './Compare';
import CreateProject from './CreateProject';
import AuthDevHub from './AuthDevHub';
import AuthDeveloperOrg from './AuthDeveloperOrg';
import CreatePackage from './CreatePackage';
import CreatePackageVersion from './CreatePackageVersion';
import InstallPackage from './InstallPackage';
import RetrieveSource from './RetrieveSource';

const steps = ['Create Project', 'Create Package', 'Retrieve Source', 'Create Package Versions'];

export default function PackageTabsNew() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          
          
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography  component="h1" variant="h5" sx={{ mt: 10, mb: -2, textAlign:'center'}}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mt:48 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button color="inherit"
              variant="contained" 
              size="large" onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) :activeStep === 0 ? (
        <React.Fragment>
          <Box sx={{ position: 'fixed', alignItems: 'center', width:'75%'}} >
              <CreateProject />
          </Box>
            
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mt:60 }}>
            <Button
              color="inherit"
              variant="contained" 
              size="large"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}

            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />

            <Button color="inherit"
              variant="contained" 
              size="large" onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              
            </Button>
          </Box>
        </React.Fragment>
      ):activeStep === 1 ? (
        <React.Fragment>
           <Box sx={{ position: 'fixed', alignItems: 'center', width:'75%'}} >
           <CreatePackage />
           </Box>
            
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mt:60 }}>
            <Button
              color="inherit"
              variant="contained" 
              size="large"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />

            <Button color="inherit"
              variant="contained" 
              size="large" onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              
            </Button>
          </Box>
        </React.Fragment>
      ):activeStep === 2 ? (
        <React.Fragment>
          <Box sx={{ position: 'fixed', alignItems: 'center', width:'75%'}} >
          <RetrieveSource />
          </Box>
            
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mt:60 }}>
            <Button
              color="inherit"
              variant="contained" 
              size="large"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />

            <Button color="inherit"
              variant="contained" 
              size="large" onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              
            </Button>
          </Box>
        </React.Fragment>
      ):activeStep === 3 ? (
        <React.Fragment>
          <Box sx={{ position: 'fixed', alignItems: 'center', width:'75%'}} >
          <CreatePackageVersion />
          </Box>
           
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mt:60 }}>
            <Button
              color="inherit"
              variant="contained" 
              size="large"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />

            <Button color="inherit"
              variant="contained" 
              size="large" onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              
            </Button>
          </Box>
        </React.Fragment>
      ):activeStep === 4 ? (
        <React.Fragment>
           
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mt:10 }}>
            <Button
              color="inherit"
              variant="contained" 
              size="large"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />

            <Button color="inherit"
              variant="contained" 
              size="large" onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              
            </Button>
          </Box>
        </React.Fragment>
      ):(
        <React.Fragment>
            <RowAndColumnSpacing />
            <CodeDiffComponent />
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mt:10}}>
            <Button
              color="inherit"
              variant="contained" 
              size="large"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />

            <Button color="inherit"
              variant="contained" 
              size="large" onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}