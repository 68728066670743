import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import './CSS/loadingCircle.css';
import {Link} from 'react-router-dom';

export default function DeploymentPageBackButton() {

  

  return (
    <div >
      <Button
         component={Link} to="/CompareContent" 
        variant="contained"
        
        size="large"
        
        sx={{ mt: 4, mb: 0 }}
      >
        Back
      </Button>
      
    </div>
  );
}
