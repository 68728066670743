import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloudIcon from '@mui/icons-material/Cloud';
import axios from 'axios';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import config from './Config';




// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function AuthDeveloperOrg() {

  
  const [buttonText, setButtonText] = React.useState("Click to Select");
  const [orgs, setOrgs] = React.useState([]);
  const [org, setOrg] = React.useState([]);


  useEffect(() => {
    // Fetch data using Axios with a GET request
    axios.defaults.timeout = config.requestTimeout;axios.post(config.basePath+'/OrgAuthorization/allDeveloperOrgs', {
      params: {
        isDeveloperOrg: true
      }
    })
      .then((response) => {
        const posts = response.data.map(obj => ({id: obj.id , userName: obj.userName}));
        setOrgs(posts);
        
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleChange = (event) => {
    setOrg(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const acc = { userName: org };
      axios.defaults.timeout = config.requestTimeout; const response = await axios.post(config.basePath+'/OrgAuthorization/chooseOrg', acc); 
      console.log(acc);
      console.log("Request Sent");
      console.log(response.data); // Handle success or failure
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
       
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        
        <Box
          sx={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <CloudIcon />
          </Avatar>
          <Typography sx={{ mb: 2 }} component="h1" variant="h5">
            Select Developer Org
          </Typography>
          
          <Box component="form" onSubmit={handleSubmit} sx={{width:450}}>
          <FormControl  fullWidth>
          <InputLabel id="label" >Select Username</InputLabel>
          <Select
          labelId="label" 
          id="select"
          value={org}
          label="Select Username"
          onChange={handleChange}
          >
          {orgs.map((item) => (
            
            <MenuItem key={item.id} value={item.userName}>{item.userName}</MenuItem>
       

        ))}
          </Select>
            <Button
              onClick={() => setButtonText("Selected")}
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {buttonText}
            </Button>
        
            </FormControl>
        </Box>
          
        </Box>
        
      </Container>
    </ThemeProvider>
  );
}
