import * as React from 'react';

const SelectedCheckboxContext = React.createContext({
  
  
  setSelectedCheckbox: []


})

export default SelectedCheckboxContext;
