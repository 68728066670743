import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from "axios";
import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { connect } from 'react-redux';
import config from './Config';


const columns = [
 
  {
    field: "projectName",
    headerName: "Project Name",
    width: 400,
  },
  {
    field: "createdDate",
    headerName: "Created Date",
    width: 400,
    valueFormatter: params => new Date(params?.value).toLocaleString(),
  },
  {
    field: "createdBy",
    headerName: "Created By",
    width: 300,
  },
];

const mapStateToProps = state => ({
  id: state.session.id
});

export default connect(mapStateToProps)(ProjectList);

function ProjectList(id) {
  const [projects, setProjects] = React.useState([]);
  const [selectedEntities, setSelectedEntities] = React.useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState();
  const [openDeleteSuccessDialog, setOpenDeleteSuccessDialog] = React.useState();
  const [responseData,setResponseData] = React.useState();
  const [openloading, setOpenloading] = React.useState(false);
  


  useEffect(() => {
    // Fetch data using Axios with a GET request
  console.log('id:',id.id)
  const userId = { id: id.id };
    axios
      .post(config.basePath+"/Projects/allProjects", userId)
      .then((response) => {
        const posts = response.data.map((obj) => ({
          id: obj.id,
          projectName: obj.projectName,
          createdDate: obj.createdDate,
          createdBy: obj.userName,
        }));
        setProjects(posts);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id]);

  useEffect(() => {
    if (responseData) {
      setOpenloading(false);
      setOpenDeleteSuccessDialog(true);
      
      const timeoutId = setTimeout(() => {
        setOpenDeleteSuccessDialog(false);
        window.location.reload(); // Reloading the page after 3 seconds
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [responseData]);

 


  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      setOpenDeleteDialog(false);
      setOpenloading(true);
      const response = await axios.post(
        config.basePath+"/Delete/deleteProjects",
        selectedEntities
      );
      console.log(selectedEntities);
      console.log("Request Sent");
      setResponseData(response);
      // Handle success or failure
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={{ height: 500, width: "100%", fontSize: 8 }}>
      <DataGrid
        rows={projects}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
              
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        pageSizeOptions={[10]}
        rowHeight={44}
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={(id) => {
          const selectedIDs = new Set(id);
          const selectedCheckbox = projects.filter((row) =>
            selectedIDs.has(row.id)
          );
          setSelectedEntities(selectedCheckbox);
          console.log(selectedCheckbox);
        }}
      />
      <Box>
        <Button
          type="submit"
          size="small"
          variant="contained"
          disabled={selectedEntities.length === 0}
          sx={{ mt: 1 }}
          onClick={() => setOpenDeleteDialog(true)}
          >
          Delete
        </Button>
        <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openloading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
        <Dialog
          open={openDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Alert!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are your sure you want to delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDelete} color="success" size="small" autoFocus variant="contained">
              Yes
            </Button>
            <Button onClick={() => setOpenDeleteDialog(false)} size="small" autoFocus variant="contained">
              No
            </Button>
          </DialogActions>
        </Dialog>
        
        <Dialog
          open={openDeleteSuccessDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Successfully deleted.
            </DialogContentText>
          </DialogContent>
          
        </Dialog>
      </Box>
    </Box>
  );
}
