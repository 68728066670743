import React, { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloudIcon from '@mui/icons-material/Cloud';
import axios from 'axios';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useUser } from './UserContext';
import PackageContext from "./package-context";
import { useContext } from "react";
import PackIdContext from './id-context';
import ProjectContext from './project-context';
import config from './Config';
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";




// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function CreatePackage() {

  const [visible, setVisible] = React.useState(false);
  const [packageName, setPackageName] = React.useState('');
  const [orgs, setOrgs] = React.useState([]);
  const [org, setOrg] = React.useState([]);
  const userData = useUser();
  const {setPack} = useContext(PackageContext);
  const {setPackId} = useContext(PackIdContext);
  const { project } = useContext(ProjectContext);
  const [responsePackage, setResponsePackage] = React.useState("");
  const [packageComplete, setPackageComplete] = React.useState("");
  const {packId} = useContext(PackIdContext);
  const [openloading, setOpenloading] = React.useState(false);
  const [openPackageResponseDialog, setOpenPackageResponseDialog] = React.useState();


  useEffect(() => {
    // Fetch data using Axios with a GET request
    const user = {id: userData.id};
    axios.post(config.basePath+'/OrgAuthorization/allDevHubOrgs', user)
      .then((response) => {
        const posts = response.data.map(obj => ({id: obj.id , userName: obj.userName}));
        setOrgs(posts);
        
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  useEffect(() => {
    if (responsePackage.includes("0000")) {
      
      setPackageComplete("Package Created Sucessfully"); 
      setOpenloading(false);
      setOpenPackageResponseDialog(true);
      const timeoutId = setTimeout(() => {
        setOpenPackageResponseDialog(false);
      
      }, 3000);

      return () => clearTimeout(timeoutId);

        } else if (responsePackage.includes("already exist. Plz try again with different name")){
         
          setPackageComplete("The Package name you've entered is already exist.  Plz try again with different name"); 
          setOpenloading(false);
          setOpenPackageResponseDialog(true);
          const timeoutId = setTimeout(() => {
            setOpenPackageResponseDialog(false);
          
          }, 3000);
    
          return () => clearTimeout(timeoutId);
        } else {
          
          setPackageComplete(""); 
          setOpenloading(false);
        }
  }, [responsePackage, packId]);

  const handleChange = (event) => {
    setOrg(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpenloading(true);
    try {
      const pack = { packageName: packageName,  devHubUsername: org, userName: userData.userName, userId: userData.id, projectName: project};
      axios.defaults.timeout = config.requestTimeout; const response = await axios.post(config.basePath+'/Packages/create', pack); 
      console.log(pack);
      console.log("Request Sent");
      // Handle success or failure
      setPack(packageName);
      setPackId(response.data);
      setResponsePackage(response.data);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };



  return (
    <ThemeProvider theme={defaultTheme}>
       
      <Container component="main" maxWidth="xs">
      <CssBaseline />
        
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <CloudIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Create Package
          </Typography>
          
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width:450 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Enter Package Name"
              name="packagename"
              autoComplete="packagename"
              autoFocus
              value={packageName}
              onChange={(e) => setPackageName(e.target.value)}
              
            />
            <FormControl  fullWidth>
            <InputLabel id="label" >Select Dev Hub Org</InputLabel>
            <Select
            labelId="label" 
            required
            id="select"
            value={org}
            label="Select Username"
            onChange={handleChange}
            >
              {orgs.map((item) => (
              
                  <MenuItem key={item.id} value={item.userName}>{item.userName}</MenuItem>
             
  
              ))}
            </Select>
         
            <Button  onClick={() => setVisible(!visible)}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={packageComplete === "Package Created Sucessfully"}
              
            >
              Create Package
            </Button>
            </FormControl> 
          
        </Box>
        <Dialog
          open={openPackageResponseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {packageComplete}
            </DialogContentText>
          </DialogContent>
          
        </Dialog>
                
        </Box>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openloading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
      </Container>
    </ThemeProvider>
  );
}
