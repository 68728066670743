import * as React from 'react';

const AnalyzerTargetOrgIdContext = React.createContext({
  
  
  setAnalyzerTargetOrgId: ""


})

export default AnalyzerTargetOrgIdContext;
