import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Navigator from './NavigatorTest';
import Content from './Content';
import Header from './Header';
import Content1 from './Content1';
import Content0 from './Content0';
import DevHubOrgContent from './DevHubOrgContent';
import ScratchOrgContent from './ScratchOrgContent';
import SourceOrgsContent from './SourceOrgsContent';
import TargetOrgsContent from './TargetOrgsContent';
import ProjectsContent from './ProjectsContent';
import PackageContent from './PackageContent';
import CompareContent from './CompareContent';
import {Routes, Route} from 'react-router-dom';
import Deployment from './Deployment';
import InstallPackage from './InstallPackage';
import SourceOrgsList from './SourceOrgsListNew';
import ForgotPassword from './ForgotPassword';
import SelectOrgType from './SelectOrgType';
import HttpsGitRepo from './HttpsGitRepo';
import SelectGitHubAuthType from './SelectGitHubAuthType';
import GitHubProjectList from './GitHubProjectList';
import MetaDataComparisonMain from './MetaDataComparisonMain';
import SignIn from './SignIn';
import SignInTarget from './SignInTarget';
import TargetOptions from './TargetOptions';
import SourceOptions from './SourceOptions';
import ConnectGitHub from './ConnectGitHub';
import ComparisonHistoryMainContent from './ComparisonHistory/ComparisonHistoryMainContent';
import ComparisonHistoryEntityPage from './ComparisonHistory/ComparisonHistoryEntityPage';
import DeploymentHistoryMainContent from './DeploymentHistory/DeploymentHistoryMainContant';
import DeploymentHistoryEntityPage from './DeploymentHistory/DeploymentHistoryEntityPage';
import InstallationHistoryMainContent from './InstallationHistory/InstallationHistoryMainContent';
import SalesforceCodeAnalyzerMain from './SalesforceCodeAnalyzer/Main/SalesforceCodeAnalyzerMain';
import SalesforceCodeAnalyzerResults from './SalesforceCodeAnalyzer/Main/SalesforceCodeAnalyzerResults';
import SalesforceCodeAnalyzerFileSelectionPage from './SalesforceCodeAnalyzer/Main/SalesforceCodeAnalyzerFileSelectionPage';
import SalesforceCodeAnalyzerDraftSelectionPage from './SalesforceCodeAnalyzer/Main/SalesforceCodeAnalyzerDraftSelectionPage';


let theme = createTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#081627',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255,255,255,0.15)',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#4fc3f7',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: theme.spacing(2),
          '& svg': {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 256;

export default function PaperBase() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <CssBaseline />
        
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          {isSmUp ? null : (
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
          )}

          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            sx={{ display: { sm: 'block', xs: 'none' } }}
          />
          
        </Box>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Header onDrawerToggle={handleDrawerToggle} />
          <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: 'background.paper' }}>
          <Routes>
            <Route path='/Content0' element={<Content0 />} />
            <Route path='/Content1' element={<Content />} />
            <Route path='/Content' element={<Content1 />} />
            <Route path='/SourceOrgsContent' element={<SourceOrgsContent />} />
            <Route path='/SourceOrgsListNew' element={<SourceOrgsList />} />
            <Route path='/DevHubOrgContent' element={<DevHubOrgContent />} />
            <Route path='/ScratchOrgContent' element={<ScratchOrgContent />} />
            <Route path='/ProjectsContent' element={<ProjectsContent />} />
            <Route path='/PackageContent' element={<PackageContent />} />
            <Route path='/CompareContent' element={<CompareContent />} />
            <Route path='/Deployment' element={<Deployment />} />
            <Route path='/InstallPackage' element={<InstallPackage />} />
            <Route path='/SelectOrgType' element={<SelectOrgType />} />
            <Route path='/HttpsGitRepo' element={<HttpsGitRepo />} />
            <Route path='/SelectGitHubAuthType' element={<SelectGitHubAuthType />} />
            <Route path='/GitHubProjectList' element={<GitHubProjectList />} />
            <Route path='/MetaDataComparisonMain' element={<MetaDataComparisonMain />} />
            <Route path='ConnectGitHub' element={<ConnectGitHub />} />
            <Route path='/ComparisonHistoryMainContent' element={<ComparisonHistoryMainContent />} />
            <Route path='/ComparisonHistoryEntityPage' element={<ComparisonHistoryEntityPage />} />
            <Route path='/DeploymentHistoryMainContent' element={<DeploymentHistoryMainContent />} />
            <Route path='/DeploymentHistoryEntityPage' element={<DeploymentHistoryEntityPage />} />
            <Route path='/InstallationHistoryMainContent' element={<InstallationHistoryMainContent />} />
            <Route path='/SalesforceCodeAnalyzerMain' element={<SalesforceCodeAnalyzerMain />} />
            <Route path='/SalesforceCodeAnalyzerResults' element={<SalesforceCodeAnalyzerResults />} />
            <Route path='/SalesforceCodeAnalyzerFileSelectionPage' element={<SalesforceCodeAnalyzerFileSelectionPage />} />
            <Route path='/SalesforceCodeAnalyzerDraftSelectionPage' element={<SalesforceCodeAnalyzerDraftSelectionPage />} />

            {/* Default Route */}
            <Route index element={<Content0 />} />
          </Routes>
         
        
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
