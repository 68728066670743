import React, { useContext } from 'react';
import { Box, Typography, Button, List, ListItem, ListItemText, Backdrop, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import AnalysisResultsContext from '../../GlobalValues/analysisResults-context';
import CommonBackdropContext from '../../GlobalValues/commonbackdrop-context';

function SalesforceCodeAnalyzerResults() {
  const { analysisResultsContext } = useContext(AnalysisResultsContext);
  const {commonBackdrop} = useContext(CommonBackdropContext);

  return (
    <Box sx={{mt:-5, padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        Analysis Report
      </Typography>
      <List>
        {analysisResultsContext.map((violation, index) => (
          <ListItem key={index} alignItems="flex-start">
            <ListItemText
              primary={
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {violation.refinedFileName}
                  <br></br>
                </Typography>
              }
              
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {violation.fileName} <br></br>
                    <br></br>
                    Violations:
                  </Typography>
                  <ul>
                    {violation.violations.map((violation, index) => (
                      <li key={index}>
                        {violation.message} (Line: {violation.line})
                      </li>
                    ))}
                  </ul>
                </React.Fragment>
              }
            />
          </ListItem>
        ))}
      </List>
     {/* <Button
        type="submit"
        size="small"
        variant="contained"
        component={Link} to="/SalesforceCodeAnalyzerMain"
        sx={{ mt: 1 }}
      >
        Back
      </Button>*/}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={commonBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

export default SalesforceCodeAnalyzerResults;
