import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import SourceOrgNameContext from '../sourceOrgName-context';
import TargetOrgNameContext from '../targetOrgName-context';
import SelectedCheckboxContext from '../SelectedCheckbox-Context';
import { useContext } from "react";
import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import CloudIcon from '@mui/icons-material/Cloud';
import Grid from '@mui/material/Grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';


const columns = [
  
   
      {
        field: 'fileName',
        headerName: 'File Name',
        width: 480,
        
      },
      {
        field: 'metadataType',
        headerName: 'Metadata type',
        width: 320,
        
      },
      {
        field: 'differenceType',
        headerName: 'Difference type',
        width: 300,
      },
];



export default function DeploymentHistoryEntityPage() {

  const { targetOrgName } = useContext(TargetOrgNameContext);
  const { sourceOrgName } = useContext(SourceOrgNameContext);
  const { selectedCheckbox } = useContext(SelectedCheckboxContext);
 
  return (
    <Container sx={{marginLeft:0, marginRight:0}}>
         <Typography sx={{ mb: 2, mt:-3}} component="h1" variant="h5">
         Deployed Metadata
          </Typography>
          
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={4}
          sx={{
            marginTop: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <CloudIcon />
            
          </Avatar>
          <Typography variant="h6">Source Org</Typography>
          <Typography variant="subtitle2">{ sourceOrgName }</Typography>
          
          </Grid>

          <Grid item xs={3}
          sx={{
            marginTop: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <ArrowForwardIcon />
            
          </Avatar>

          </Grid>


          <Grid item xs={4}
          sx={{
            marginTop: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
           
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <CloudUploadIcon />
            
          </Avatar>
          <Typography variant="h6">Target Org</Typography>
          <Typography variant="subtitle2">{ targetOrgName }</Typography>
          </Grid>
          </Grid>



    <Box sx={{ height: 320, width: '100%', fontSize:6, mt:4 }}>
      <DataGrid
        rows={selectedCheckbox}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[5]}
        rowHeight={30}
        
       
       
        
      />
       
    </Box>

    <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3 }}>
    
    <Grid item xs={6}>
     
    <Button
                component={Link} to="/DeploymentHistoryMainContent"
                variant="contained" 
                size="small" 
                sx={{mt:4}}
                >
                  Back</Button>
   
    </Grid>
    
    </Grid>
        
    </Container>
  );
}
