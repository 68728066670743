import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import axios from 'axios';
import { useEffect } from 'react';
import DialogActions from '@mui/material/DialogActions';
import {Link} from 'react-router-dom';
import config from './Config';


const defaultTheme = createTheme();

export default function ForgotPassword() {

    const [email, setEmail] = React.useState("");
    const [openloading, setOpenloading] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [responseRequest, setResponseRequest] = React.useState("");

    const handleRefreshClick = () => {
      window.location.reload();
    };

    const handlePasswordReset = () => {
      setOpenDialog(true);
      setTimeout(() => {
        setOpenDialog(false);
      }, 3000);
    };


    useEffect(() => {
      if (responseRequest) {
        setOpenloading(false);
        setOpenDialog(true);
       
      }
    }, [responseRequest]);


    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        setOpenloading(true);
        const userParam = { email: email};
        axios.defaults.timeout = config.requestTimeout; const response = await axios.post(config.basePath+'/User/resetEmail', userParam); 
        console.log(userParam);
        console.log("Request Sent");
        setResponseRequest(response.data);
        console.log(response.data); 
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    };

    



  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar variant="dense">
          
         
          <Typography variant="h6" color="inherit" component="div">
            Force Forge
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1, width:600 }}>
            <TextField
            type="email"
            
              margin="normal"
              required
              fullWidth
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
             onClick={handleSubmit}
              
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              
            >
              Send Link 
            </Button>
            
          
          </Box>
                  <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={openloading}
                    >
                        <CircularProgress color="inherit" />
                  </Backdrop>

              <Dialog
                open={openDialog}
        
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Notification"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    {responseRequest}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                      <Button   
                        component={Link} to="/AccountSignInPage"
                        autoFocus 
                        variant="contained"
                        >
                      Finish
                      </Button>
                  </DialogActions>
                </Dialog>
        </Box>
      </Container>
      <Box>
      <Button
             component={Link} to="/AccountSignInPage"
              type="submit"
              
              variant="contained"
              sx={{ mt: 30, mb: 0, ml:4 }}
              
            >
              Back 
            </Button>
      </Box>
    </ThemeProvider>
  );
}
