import * as React from 'react';

import Box from '@mui/material/Box';
import PackageTabsNew from './PackageTabsNew';
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Button from '@mui/material/Button';



export default function Content() {


  const [openloading, setOpenloading] = React.useState(false);


  return (
    
     
        
          <Box sx={{mt:-3}}>
            <PackageTabsNew />
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openloading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
      
      
    
  );
}
