import React, { useState, useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from "../Config";
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../sessionActions';
import GoogleButton from 'react-google-button'

export const SignUpWithGoogle = () => {

  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  console.log(user);
  const dispatch = useDispatch();

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then(async (res) => {
          console.log(res.data);
          const user = {
            email: res.data.email,
            password: 'forceforge079',
            isAgreeTermsConditions: true,
          };
          axios.defaults.timeout = config.requestTimeout; const responseSignupRequestWithGoogle = await axios.post(config.basePath+"/User/signup",user);
          // setProfile(res.data);
          console.log(responseSignupRequestWithGoogle);
          if (responseSignupRequestWithGoogle.data) {
            axios.defaults.timeout = config.requestTimeout; const signupToSignInwithGoogle = await axios.post(config.basePath+"/User/login",user);
            dispatch(loginSuccess(signupToSignInwithGoogle.data.id));
            navigate('/Content0');
          }
        })
        .catch((err) => console.log(err));
    }
  }, [user,dispatch,navigate]);

  return (
    <>
      <GoogleButton onClick={login} type="light" label='Sign up with Google' style={{ width: '100%' }}/>
    </>
  );
};


//SignIn function

export const SignInWithGoogle = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const dispatch = useDispatch();

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then(async (res) => {
          console.log(res.data);
          const user = {
            email: res.data.email,
            password: 'forceforge079',
            isAgreeTermsConditions: true,
          };
          axios.defaults.timeout = config.requestTimeout;  const responseSignupRequestWithGoogle = await axios.post(config.basePath+"/User/signup",user);
          // setProfile(res.data);
          console.log(responseSignupRequestWithGoogle);
          if (responseSignupRequestWithGoogle.data) {
            axios.defaults.timeout = config.requestTimeout;  const signupToSignInwithGoogle = await axios.post(config.basePath+"/User/login",user);
            dispatch(loginSuccess(signupToSignInwithGoogle.data.id));
            navigate('/Content0');
          }
        })
        .catch((err) => console.log(err));
    }
  }, [user,dispatch,navigate]);

  return (
    <>
 
      <GoogleButton onClick={login} type="light" label='Sign in with Google' style={{ width: '100%' }}/>

    </>
  );

};

