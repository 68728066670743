import * as React from 'react';

const ComparisonOrgsDataBackdropContext = React.createContext({
  
  
  setComparisonOrgsDataBackdrop: false


})

export default ComparisonOrgsDataBackdropContext;
