import * as React from 'react';

const ShowSignInTargetContext = React.createContext({
  
    setShowSignInTarget : '',
  

})

export default ShowSignInTargetContext;
